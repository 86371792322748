import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";
import { UserService } from '../service/user.service';

@Component({
  selector: 'demographics-demo',
  templateUrl: './demographicsdemo.component.html'
})
export class DemographicsDemoComponent implements OnInit {
  public activetab = "demographics";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public centerBasic: any = [];
  public circleBasic: any;
  public isBasicFlag = false;
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  public searchRadius = '';
  public searchRadiusUnit = 'meters';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    if (this.isBasicFlag) {
      if (this.searchRadius == '') {
        $('#geoLifeDemoSearchError').html('Please provide search radius.');
        $('#geoLifeDemoSearchError').show();
        return;
      }
    }
    this.geoApisGetAddressGeocode(latlng.lat, latlng.lng);
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removePolygons();
    if (this.activetab == 'demographics') {
      if (this.centerBasic.length) {
        let circleRadius = this.getCircleRadius();
        this.circleBasic = L.circle(this.centerBasic, { radius: circleRadius }).addTo(this.map);
        this.map.fitBounds(this.circleBasic.getBounds());
      }
    }
  };
  getCircleRadius = function () {
    let circleRadius;
    if (this.searchRadiusUnit == 'meters') {
      circleRadius = this.searchRadius;
    }
    if (this.searchRadiusUnit == 'kilometers') {
      circleRadius = this.searchRadius * 1000;
    }
    if (this.searchRadiusUnit == 'feet') {
      circleRadius = this.searchRadius * 0.3048;
    }
    if (this.searchRadiusUnit == 'miles') {
      circleRadius = this.searchRadius * 1609.34;
    }
    return circleRadius;
  };
  removePolygons = function () {
    if (this.circleBasic) {
      this.map.removeLayer(this.circleBasic);
    }
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoLifeDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.removePolygons();
    $('#geoLifeDemoSearchError').hide();
    $('#geoLifeDemographicsError').hide();
    $('#geoLifeDemographicsResult').html('');
    $('#geoLifeSegmentationError').hide();
    $('#geoLifeSegmentationResult').html('');
    if (address != '') {
      if (this.isBasicFlag) {
        if (this.searchRadius == '') {
          $('#geoLifeDemoSearchError').html('Please provide search radius.');
          $('#geoLifeDemoSearchError').show();
          return;
        }
      }
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoLifeDemoSearchError').html('Address cannot be blank.');
      $('#geoLifeDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoLifeDemographicsByLocation(lat, lng);
          this.sampleDemoGeoLifeSegmentationByLocation(lat, lng);
        }
        else {
          $('#geoLifeDemographicsError').hide();
          $('#geoLifeDemographicsResult').html('');
          $('#geoLifeSegmentationError').hide();
          $('#geoLifeSegmentationResult').html('');
          $('#geoLifeDemoSearchError').html('No information available for this address.');
          $('#geoLifeDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoLifeDemographicsByLocation = function (lat, lng) {
    $('#geoLifeDemographicsError').hide();
    $('#geoLifeDemographicsResult').html('');
    // this.boundaryBasic = null;
    this.centerBasic = [];
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    if (!this.isBasicFlag) {
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoLifeDemographicsByLoc', tmpData).subscribe(
        success => {
          if (success.themes) {
            var tmpHtml = '<h2 class="zeromargintop padSide">Overall Demographic</h2><div class="accordion" id="accordiondemo">';
            for (var key in success.themes) {
              var tmpHeadName = key.charAt(0).toUpperCase() + key.slice(1);
              tmpHtml += '<div class="card">';
              tmpHtml += '<div class="card-header" id="ahj_head' + key + '">';
              tmpHtml += '<h5 class="mb-0">';
              tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content' + key + '" aria-controls="#ahj_content' + key + '"';
              if (key != 'populationTheme') {
                tmpHtml += ' aria-expanded="false"';
              }
              else {
                tmpHtml += ' aria-expanded="true"';
              }
              tmpHtml += '>' + tmpHeadName + '</button></h5></div>';
              tmpHtml += '<div id="ahj_content' + key + '" class="collapse';
              if (key == 'populationTheme') {
                tmpHtml += ' show';
              }
              tmpHtml += '" aria-labelledby="ahj_head' + key + '" data-parent="#accordiondemo"><div class="card-body">';
              if (success.themes[key].rangeVariable) {
                for (var i = 0; i < success.themes[key].rangeVariable.length; i++) {
                  var arr = success.themes[key].rangeVariable[i].field;
                  var maxNode = arr.reduce(function (a, b) {
                    var tmpNode = a;
                    var aVal = parseFloat(a.value);
                    var bVal = parseFloat(b.value);
                    if (aVal < bVal) {
                      tmpNode = b;
                    }
                    return tmpNode;
                  });
                  tmpHtml += '<div class="resultAccValFocus">' + success.themes[key].rangeVariable[i].name + '</div>';
                  tmpHtml += '<table class="table table-condensed table-open">';
                  for (var j = 0; j < success.themes[key].rangeVariable[i].field.length; j++) {
                    tmpHtml += '<tr';
                    if (success.themes[key].rangeVariable[i].field[j].value == maxNode.value) {
                      tmpHtml += ' class="boldrow"';
                    }
                    tmpHtml += '><td class="resultAccVal">' + success.themes[key].rangeVariable[i].field[j].description + '</td>';
                    tmpHtml += '<td class="resultAccVal">' + success.themes[key].rangeVariable[i].field[j].value + '</td></tr>';
                  }
                  tmpHtml += '</table>';
                }
              }
              if (success.themes[key].individualValueVariable) {
                tmpHtml += '<div class="adjustpad"><div class="subhead">Summary</div><table class="table table-condensed-extra table-unstyled">';
                for (var i = 0; i < success.themes[key].individualValueVariable.length; i++) {
                  tmpHtml += '<tr><td class="resultAccVal">' + success.themes[key].individualValueVariable[i].name + '</td>';
                  tmpHtml += '<td class="resultAccVal">' + success.themes[key].individualValueVariable[i].value + '</td></tr>';
                }
                tmpHtml += '</table></div>';
              }
              tmpHtml += '</div></div></div>';
            }
            tmpHtml += '</div>';
            $('#geoLifeDemographicsResult').html(tmpHtml);
          }
          else {
            $('#geoLifeDemographicsError').html('No information available for this address/location.');
            $('#geoLifeDemographicsError').show();
          }
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            $('#geoLifeDemographicsError').html(error.error.errors[0].errorDescription);
          }
          else {
            $('#geoLifeDemographicsError').html(error.statusText);
          }
          $('#geoLifeDemographicsError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#geoLifeDemographicsError').hide();
          }
        }
      );
    }
    else {
      tmpData["searchRadius"] = this.searchRadius;
      tmpData["searchRadiusUnit"] = this.searchRadiusUnit;
      this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoLifeDemographicsBasic', tmpData).subscribe(
        success => {
          if (success.themes) {
            var tmpHtml = '<h2 class="zeromargintop padSide">Overall Demographic</h2><div class="accordion" id="accordiondemo">';
            for (var key in success.themes) {
              var tmpHeadName = key.charAt(0).toUpperCase() + key.slice(1);
              tmpHtml += '<div class="card">';
              tmpHtml += '<div class="card-header" id="ahj_head' + key + '">';
              tmpHtml += '<h5 class="mb-0">';
              tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content' + key + '" aria-controls="#ahj_content' + key + '"';
              if (key != 'populationTheme') {
                tmpHtml += ' aria-expanded="false"';
              }
              else {
                tmpHtml += ' aria-expanded="true"';
              }
              tmpHtml += '>' + tmpHeadName + '</button></h5></div>';
              tmpHtml += '<div id="ahj_content' + key + '" class="collapse';
              if (key == 'populationTheme') {
                tmpHtml += ' show';
              }
              tmpHtml += '" aria-labelledby="ahj_head' + key + '" data-parent="#accordiondemo"><div class="card-body">';
              if (success.themes[key].rangeVariable) {
                for (var i = 0; i < success.themes[key].rangeVariable.length; i++) {
                  var arr = success.themes[key].rangeVariable[i].field;
                  var maxNode = arr.reduce(function (a, b) {
                    var tmpNode = a;
                    var aVal = parseFloat(a.value);
                    var bVal = parseFloat(b.value);
                    if (aVal < bVal) {
                      tmpNode = b;
                    }
                    return tmpNode;
                  });
                  tmpHtml += '<div class="resultAccValFocus">' + success.themes[key].rangeVariable[i].name + '</div>';
                  tmpHtml += '<table class="table table-condensed table-open">';
                  for (var j = 0; j < success.themes[key].rangeVariable[i].field.length; j++) {
                    tmpHtml += '<tr';
                    if (success.themes[key].rangeVariable[i].field[j].value == maxNode.value) {
                      tmpHtml += ' class="boldrow"';
                    }
                    tmpHtml += '><td class="resultAccVal">' + success.themes[key].rangeVariable[i].field[j].description + '</td>';
                    tmpHtml += '<td class="resultAccVal">' + success.themes[key].rangeVariable[i].field[j].value + '</td></tr>';
                  }
                  tmpHtml += '</table>';
                }
              }
              if (success.themes[key].individualValueVariable) {
                tmpHtml += '<div class="adjustpad"><div class="subhead">Summary</div><table class="table table-condensed-extra table-unstyled">';
                for (var i = 0; i < success.themes[key].individualValueVariable.length; i++) {
                  tmpHtml += '<tr><td class="resultAccVal">' + success.themes[key].individualValueVariable[i].name + '</td>';
                  tmpHtml += '<td class="resultAccVal">' + success.themes[key].individualValueVariable[i].value + '</td></tr>';
                }
                tmpHtml += '</table></div>';
              }
              tmpHtml += '</div></div></div>';
            }
            tmpHtml += '</div>';
            $('#geoLifeDemographicsResult').html(tmpHtml);
            this.centerBasic = [lat, lng];
          }
          else {
            $('#geoLifeDemographicsError').html('No information available for this address/location.');
            $('#geoLifeDemographicsError').show();
          }
          $('.geoApisDemoMapLoader').hide();
          this.toogleDemoTab(this.activetab);
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            $('#geoLifeDemographicsError').html(error.error.errors[0].errorDescription);
          }
          else {
            $('#geoLifeDemographicsError').html(error.statusText);
          }
          $('#geoLifeDemographicsError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#geoLifeDemographicsError').hide();
          }
        }
      );
    }
  };
  sampleDemoGeoLifeSegmentationByLocation = function (lat, lng) {
    $('#geoLifeSegmentationError').hide();
    $('#geoLifeSegmentationResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoLifeSegmentationByLoc', tmpData).subscribe(
      success => {
        if (success.themes) {
          var tmpHtml = '<h2 class="zeromargintop">Lifestyle Theme</h2><table class="table table-condensed table-unstyled">';
          for (var key in success.themes) {
            if (success.themes[key].individualValueVariable) {
              for (var i = 0; i < success.themes[key].individualValueVariable.length; i++) {
                if (i % 2 == 0) {
                  tmpHtml += '<tr>';
                }
                tmpHtml += '<td><div class="resultKey">' + success.themes[key].individualValueVariable[i].name + '</div><div class="resultVal">';
                if (success.themes[key].individualValueVariable[i].value != '') {
                  tmpHtml += success.themes[key].individualValueVariable[i].value;
                }
                else {
                  tmpHtml += '-';
                }
                tmpHtml += '</div></td>';
                if (i % 2 == 1) {
                  tmpHtml += '</tr>';
                }
              }
            }
          }
          tmpHtml += '</table>';
          $('#geoLifeSegmentationResult').html(tmpHtml);
        }
        else {
          $('#geoLifeSegmentationError').html('No information available for this address/location.');
          $('#geoLifeSegmentationError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoLifeSegmentationError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoLifeSegmentationError').html(error.statusText);
        }
        $('#geoLifeSegmentationError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoLifeSegmentationError').hide();
        }
      }
    );
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        if (this.isBasicFlag) {
          if (this.searchRadius == '') {
            $('#geoLifeDemoSearchError').html('Please provide search radius.');
            $('#geoLifeDemoSearchError').show();
            return;
          }
        }
        that.geoApisGetAddressGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  geoApisGetAddressGeocode = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoLifeDemoSearchError').hide();
    $('#geoLifeDemographicsError').hide();
    $('#geoLifeDemographicsResult').html('');
    $('#geoLifeSegmentationError').hide();
    $('#geoLifeSegmentationResult').html('');
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.sampleDemoGeoLifeDemographicsByLocation(lat, lng);
          this.sampleDemoGeoLifeSegmentationByLocation(lat, lng);
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoLifeDemoSearchError').html('No information available for this location.');
          $('#geoLifeDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
}
