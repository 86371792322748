import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
  selector: 'autocomplete-demo',
  templateUrl: './autocompletedemo.component.html',
  styleUrls:['./autocompleteapi.component.css']
})
export class AutocompleteDemoComponent implements OnInit {
  public activetab = 'typeahead';
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) { };
  ngOnInit() {
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
  };
}
