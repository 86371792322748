import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'telecomm-demo',
  templateUrl: './telecommdemo.component.html'
})
export class TelecommDemoComponent implements OnInit {
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    this.geoApisGetAddressGeocode(latlng.lat, latlng.lng);
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoCommDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    $('#geoCommDemoSearchError').hide();
    $('#geoCommratecenterError').hide();
    $('#geoCommratecenterResult').html('');
    if (address != '') {
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoCommDemoSearchError').html('Address cannot be blank.');
      $('#geoCommDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoCommByLocation(lat, lng);
        }
        else {
          $('#geoCommratecenterError').hide();
          $('#geoCommratecenterResult').html('');
          $('#geoCommDemoSearchError').html('No information available for this address.');
          $('#geoCommDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoCommByLocation = function (lat, lng) {
    $('#geoCommratecenterError').hide();
    $('#geoCommratecenterResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      areaCodeInfo: true,
      level: 'detail'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoCommRateCenterByLoc', tmpData).subscribe(
      success => {
        if (success.areaCodeInfoList) {
          var tmpHtml = '<div class="accordion" id="accordiondemo">';
          tmpHtml += '<div class="card">';
          tmpHtml += '<div class="card-header" id="ahj_head">';
          tmpHtml += '<h5 class="mb-0">';
          tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content" aria-controls="#ahj_content" aria-expanded="true"';
          tmpHtml += '>' + success.alternateName + ' - ' + success.areaName1 + '</button></h5></div>';
          tmpHtml += '<div id="ahj_content" class="collapse show" aria-labelledby="ahj_head" data-parent="#accordiondemo"><div class="card-body">';
          var loopcount = (success.areaCodeInfoList.length > 3) ? 3 : success.areaCodeInfoList.length;
          for (var i = 0; i < loopcount; i++) {
            if (success.areaCodeInfoList[i].companyName) {
              tmpHtml += '<h4 class="zeromargintop">' + success.areaCodeInfoList[i].companyName + '</h4>';
            }
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            if (success.areaCodeInfoList[i].areaName4) {
              tmpHtml += '<tr><td>Suburb/Village</td><td>' + success.areaCodeInfoList[i].areaName4 + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].lata) {
              tmpHtml += '<tr><td>Local Access and Transport Areas (LATA)</td><td>' + success.areaCodeInfoList[i].lata + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].npa) {
              tmpHtml += '<tr><td>Area Code (NPA)</td><td>' + success.areaCodeInfoList[i].npa + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].nxx) {
              tmpHtml += '<tr><td>Prefix (NXX)</td><td>' + success.areaCodeInfoList[i].nxx + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].ocn) {
              tmpHtml += '<tr><td>Operating company number(OCN)</td><td>' + success.areaCodeInfoList[i].ocn + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].ocnCategory) {
              tmpHtml += '<tr><td>Type of carrier (ocnCategory)</td><td>' + success.areaCodeInfoList[i].ocnCategory + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].startRange) {
              tmpHtml += '<tr><td>Start Range</td><td>' + success.areaCodeInfoList[i].startRange + '</td></tr>';
            }
            if (success.areaCodeInfoList[i].endRange) {
              tmpHtml += '<tr><td>End Range</td><td>' + success.areaCodeInfoList[i].endRange + '</td></tr>';
            }
            tmpHtml += '</table>';
          }
          if (success.areaCodeInfoList.length > 3) {
            tmpHtml += '<div title="For purposes of this demo, we are showing only the top three results"><i class="pbi-icon-mini pbi-menu-dots"></i></div>';
          }
          tmpHtml += '</div></div></div>';
          tmpHtml += '</div>';
          $('#geoCommratecenterResult').html(tmpHtml);
        }
        else {
          $('#geoCommratecenterError').html('No information available for this address/location.');
          $('#geoCommratecenterError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoCommratecenterError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoCommratecenterError').html(error.statusText);
        }
        $('#geoCommratecenterError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoCommratecenterError').hide();
        }
      }
    );
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        that.geoApisGetAddressGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  geoApisGetAddressGeocode = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoCommDemoSearchError').hide();
    $('#geoCommratecenterError').hide();
    $('#geoCommratecenterResult').html('');
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.sampleDemoGeoCommByLocation(lat, lng);
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoCommDemoSearchError').html('No information available for this location.');
          $('#geoCommDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
}
