import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';
import { UserService } from '../service/user.service';

@Component({
  selector: 'streets-api',
  templateUrl: './streetsapi.component.html'
})
export class StreetsApiComponent implements OnInit {
  public docsURL: any = '';
  public nibaParamLink: any = '';
  public niblParamLink: any = '';
  public nibaRadio: any = 'searchRadius';
  public nibaSearchRadius: any = '10';
  public nibaSearchRadiusUnit: any = 'Miles';
  public nibaDriveTime = '';
  public nibaDriveTimeUnit = '';
  public niblRadio: any = 'searchRadius';
  public niblSearchRadius: any = '10';
  public niblSearchRadiusUnit: any = 'Miles';
  public niblDriveTime = '';
  public niblDriveTimeUnit = '';
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'speed limit' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else {
      this.setParamLink(docsURL);
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  setParamLink = function (docsURL) {
    this.docsURL = docsURL;
    this.nibaParamLink = docsURL + 'Streets/ByAddress/query_parameters.html';
    this.niblParamLink = docsURL + 'Streets/ByLocation/query_parameters.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_streetByAddrRes':
        this.get_streetByAddrUri = "";
        this.get_streetByAddrRes = null;
        this.get_streetByAddrResStatus = "";
        this.get_streetByAddrResType = "";
        break;
      case 'get_streetByLocRes':
        this.get_streetByLocUri = "";
        this.get_streetByLocRes = null;
        this.get_streetByLocResStatus = "";
        this.get_streetByLocResType = "";
        break;
      case 'get_streetSpeedRes':
        this.get_streetSpeedUri = "";
        this.get_streetSpeedRes = null;
        this.get_streetSpeedResStatus = "";
        this.get_streetSpeedResType = "";
        break;
    }
  };
  changeNibaRadio = function () {
    if (this.nibaRadio == 'searchRadius') {
      this.nibaSearchRadius = '10';
      this.nibaSearchRadiusUnit = 'Miles';
      this.nibaDriveTime = '';
      this.nibaDriveTimeUnit = '';
    }
    if (this.nibaRadio == 'driveTime') {
      this.nibaSearchRadius = '';
      this.nibaSearchRadiusUnit = '';
      this.nibaDriveTime = '30';
      this.nibaDriveTimeUnit = 'Minutes';
    }
  };
  changeNiblRadio = function () {
    if (this.niblRadio == 'searchRadius') {
      this.niblSearchRadius = '10';
      this.niblSearchRadiusUnit = 'Miles';
      this.niblDriveTime = '';
      this.niblDriveTimeUnit = '';
    }
    if (this.niblRadio == 'driveTime') {
      this.niblSearchRadius = '';
      this.niblSearchRadiusUnit = '';
      this.niblDriveTime = '30';
      this.niblDriveTimeUnit = 'Minutes';
    }
  };
  public geoStreetTryApiMajInByAddraddress = '4750 Walnut St., Boulder CO, 80301';
  public geoStreetTryApiMajInByAddrmaxCandidates = 1;
  public geoStreetTryApiMajInByAddrroadClass = 'All';
  public geoStreetTryApiMajInByAddrhistoricSpeed = 'None';
  public geoStreetTryApiMajInByAddrDataType = 'json';
  public get_streetByAddrUri = "";
  public get_streetByAddrRes = null;
  public get_streetByAddrResStatus = "";
  public get_streetByAddrResType = "";
  tryApiGeoStreetMajInByAddr = function (btnId) {
    var address = this.geoStreetTryApiMajInByAddraddress;
    var maxCandidates = this.geoStreetTryApiMajInByAddrmaxCandidates;
    var roadClass = this.geoStreetTryApiMajInByAddrroadClass;
    var historicSpeed = this.geoStreetTryApiMajInByAddrhistoricSpeed;
    var dataType = this.geoStreetTryApiMajInByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (this.nibaSearchRadius != '') {
      tmpData['searchRadius'] = this.nibaSearchRadius;
    }
    if (this.nibaSearchRadiusUnit != '') {
      tmpData['searchRadiusUnit'] = this.nibaSearchRadiusUnit;
    }
    if (this.nibaDriveTime != '') {
      tmpData['driveTime'] = this.nibaDriveTime;
    }
    if (this.nibaDriveTimeUnit != '') {
      tmpData['driveTimeUnit'] = this.nibaDriveTimeUnit;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (roadClass != '') {
      tmpData['roadClass'] = roadClass;
    }
    if (historicSpeed != '') {
      tmpData['historicSpeed'] = historicSpeed;
    }
    if (address != '') {
      var methodName = '/tryApiGeoStreetMajInByAddr';
      let requestUri = this.liapiservice.getCompleteUri('streets', 'Nearest Intersection By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_streetByAddrUri = requestUri;
          this.get_streetByAddrResType = dataType;
          if(this.get_streetByAddrResStatus.includes('429')){
            this.get_streetByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_streetByAddrResStatus = '200 success';
            this.get_streetByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_streetByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_streetByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoStreetTryApiMajInByLoclatitude = 40.018301;
  public geoStreetTryApiMajInByLoclongitude = -105.240976;
  public geoStreetTryApiMajInByLocmaxCandidates = 1;
  public geoStreetTryApiMajInByLocroadClass = 'All';
  public geoStreetTryApiMajInByLochistoricSpeed = 'None';
  public geoStreetTryApiMajInByLocDataType = 'json';
  public get_streetByLocUri = "";
  public get_streetByLocRes = null;
  public get_streetByLocResStatus = "";
  public get_streetByLocResType = "";
  tryApiGeoStreetMajInByLoc = function (btnId) {
    var latitude: any = this.geoStreetTryApiMajInByLoclatitude;
    var longitude: any = this.geoStreetTryApiMajInByLoclongitude;
    var maxCandidates = this.geoStreetTryApiMajInByLocmaxCandidates;
    var roadClass = this.geoStreetTryApiMajInByLocroadClass;
    var historicSpeed = this.geoStreetTryApiMajInByLochistoricSpeed;
    var dataType = this.geoStreetTryApiMajInByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (this.niblSearchRadius != '') {
      tmpData['searchRadius'] = this.niblSearchRadius;
    }
    if (this.niblSearchRadiusUnit != '') {
      tmpData['searchRadiusUnit'] = this.niblSearchRadiusUnit;
    }
    if (this.niblDriveTime != '') {
      tmpData['driveTime'] = this.niblDriveTime;
    }
    if (this.niblDriveTimeUnit != '') {
      tmpData['driveTimeUnit'] = this.niblDriveTimeUnit;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (roadClass != '') {
      tmpData['roadClass'] = roadClass;
    }
    if (historicSpeed != '') {
      tmpData['historicSpeed'] = historicSpeed;
    }
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoStreetMajInByLoc';
        let requestUri = this.liapiservice.getCompleteUri('streets', 'Nearest Intersection By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_streetByLocUri = requestUri;
            this.get_streetByLocResType = dataType;
            if(this.get_streetByLocResStatus.includes('429')){
              this.get_streetByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_streetByLocResStatus = '200 success';
              this.get_streetByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_streetByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_streetByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public tryApiGeoStreetMajSpeedIntersectionPoint = '-74.044812,40.61171;-74.045644,40.613625;-74.046322,40.615183';
  public tryApiGeoStreetMajSpeedIntersectionDataType = 'json';
  public get_streetSpeedUri = "";
  public get_streetSpeedRes = null;
  public get_streetSpeedResStatus = "";
  public get_streetSpeedResType = "";
  tryApiGeoStreetMajSpeedIntersection = function (btnId) {
    var path: any = this.tryApiGeoStreetMajSpeedIntersectionPoint;
    var dataType = this.tryApiGeoStreetMajSpeedIntersectionDataType;
    var tmpData = {
      path: path,
      dataType: dataType
    };
    if (path != '') {
      var methodName = '/tryApiGeoStreetMajSpeedIntersection';
      let requestUri = this.liapiservice.getCompleteUri('streets', 'Nearest Speedlimit', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_streetSpeedUri = requestUri;
          this.get_streetSpeedResType = dataType;
          if(this.get_streetSpeedResStatus.includes('429')){
            this.get_streetSpeedUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_streetSpeedResStatus = '200 success';
            this.get_streetSpeedRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_streetSpeedResStatus = `${error.status} ${error.statusText}`;
              this.get_streetSpeedRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
