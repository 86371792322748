import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { UserService } from '../service/user.service';

@Component({
  selector: 'zones-demo',
  templateUrl: './zonesdemo.component.html'
})
export class ZonesDemoComponent implements OnInit {
  public activetab = 'travel';
  public travelmode = 'driving';
  public traveltype = 'bytime';
  public lat = '';
  public lng = '';
  public bytimeinput = 5;
  public bytimeselect = 'min';
  public bydistanceinput = 500;
  public bydistanceselect = 'm';
  public maxOffroadDistance = 1;
  public maxOffroadDistanceUnit = 'mi';
  public basicinput = 500;
  public basicselect = 'meters';
  public travelBoundaryRes = null;
  public basicBoundaryRes = null;
  public poiBoundaryRes = null;
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public polygon;
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private userService: UserService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    this.lat = latlng.lat;
    this.lng = latlng.lng;
    this.geoApisGetAddressGeocode();
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    that.lat = '';
    that.lng = '';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.lat = position.coords.latitude;
        that.lng = position.coords.longitude;
        that.geoApisGetAddressGeocode();
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.renderResponse();
  };
  settravelmode = function (travelmode) {
    this.travelmode = travelmode;
    this.sampleDemoGeoZoneTravelBoundary();
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoZoneDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
    $('#geoZoneDemoSearchError').hide();
    $('#geoZoneTravelError').hide();
    $('#geoZoneBasicError').hide();
    $('#geoZonePoiError').hide();
    if (address != '') {
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoZoneDemoSearchError').html('Address cannot be blank.');
      $('#geoZoneDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    this.lat = '';
    this.lng = '';
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          this.lat = success.candidates[0].geometry.coordinates[1];
          this.lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(this.lat, this.lng), 14);
          this.newMarker = L.marker([this.lat, this.lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoZoneTravelBoundary();
          this.sampleDemoGeoZoneBasicBoundary();
          this.sampleDemoGeoZonePoiBoundary();
        }
        else {
          $('#geoZoneTravelError').hide();
          $('#geoZoneBasicError').hide();
          $('#geoZoneDemoSearchError').html('No information available for this address.');
          $('#geoZoneDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoZoneTravelBoundary = function () {
    $('.geoApisDemoMapLoader').show();
    this.travelBoundaryRes = null;
    $('#geoZoneTravelError').hide();
    var point = this.lng + ',' + this.lat + ',epsg:4326';
    var tmpData = {
      traveltype: this.traveltype,
      point: point,
      costs: '',
      costUnit: '',
      db: this.travelmode
    };
    if (this.traveltype == 'bytime') {
      tmpData['costs'] = this.bytimeinput;
      tmpData['costUnit'] = this.bytimeselect;
    }
    if (this.traveltype == 'bydistance') {
      tmpData['costs'] = this.bydistanceinput;
      tmpData['costUnit'] = this.bydistanceselect;
    }
    if (this.maxOffroadDistance != '') {
      tmpData['maxOffroadDistance'] = this.maxOffroadDistance;
    }
    if (this.maxOffroadDistanceUnit != '') {
      tmpData['maxOffroadDistanceUnit'] = this.maxOffroadDistanceUnit;
    }
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoZoneTravelBoundary', tmpData).subscribe(
      success => {
        if (success.travelBoundary) {
          this.travelBoundaryRes = success.travelBoundary;
          if(this.activetab == 'travel'){
            this.renderResponse();
          }
        }
        else {
          $('#geoZoneTravelError').html('No information available for this location.');
          $('#geoZoneTravelError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoZoneTravelError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoZoneTravelError').html(error.statusText);
        }
        $('#geoZoneTravelError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoZoneTravelError').hide();
        }
      }
    );
  };
  sampleDemoGeoZoneBasicBoundary = function () {
    $('.geoApisDemoMapLoader').show();
    this.basicBoundaryRes = null;
    $('#geoZoneBasicError').hide();
    var tmpData = {
      latitude: this.lat,
      longitude: this.lng,
      distance: this.basicinput,
      distanceUnit: this.basicselect
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoZoneBasicBoundary', tmpData).subscribe(
      success => {
        if (success.geometry) {
          this.basicBoundaryRes = success;
          if(this.activetab == 'basic'){
            this.renderResponse();
          }
        }
        else {
          $('#geoZoneBasicError').html('No information available for this address/location.');
          $('#geoZoneBasicError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoZoneBasicError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoZoneBasicError').html(error.statusText);
        }
        $('#geoZoneBasicError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoZoneBasicError').hide();
        }
      }
    );
  };
  sampleDemoGeoZonePoiBoundary = function () {
    $('.geoApisDemoMapLoader').show();
    this.poiBoundaryRes = null;
    $('#geoZonePoiError').hide();
    $('#geoZonePoiResult').html('');
    var tmpData = {
      latitude: this.lat,
      longitude: this.lng,
      dataType: 'json'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoZonePoiBoundaryByLoc', tmpData).subscribe(
      success => {
        if (success.geometry) {
          this.poiBoundaryRes = success;
          if(this.activetab == 'poi'){
            this.renderResponse();
          }
        }
        else {
          $('#geoZonePoiError').html('No information available for this address/location.');
          $('#geoZonePoiError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoZonePoiError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoZonePoiError').html(error.statusText);
        }
        $('#geoZonePoiError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoZonePoiError').hide();
        }
      }
    );
  };
  geoApisGetAddressGeocode = function () {
    $('.geoApisDemoMapLoader').show();
    $('#geoZoneDemoSearchError').hide();
    $('#geoZoneTravelError').hide();
    $('#geoZoneBasicError').hide();
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
    this.map.setView(L.latLng(this.lat, this.lng), 14);
    this.newMarker = L.marker([this.lat, this.lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: this.lng,
      y: this.lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.sampleDemoGeoZoneTravelBoundary();
          this.sampleDemoGeoZoneBasicBoundary();
          this.sampleDemoGeoZonePoiBoundary();
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoZoneDemoSearchError').html('No information available for this location.');
          $('#geoZoneDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  renderResponse = function () {
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
    let geometry;
    let locationsArr = [];
    if (this.activetab == 'travel') {
      if (this.travelBoundaryRes != null) {
        if (this.travelBoundaryRes.costs[0].geometry) {
          geometry = this.travelBoundaryRes.costs[0].geometry;
        }
      }
    }
    else if (this.activetab == 'basic') {
      if (this.basicBoundaryRes != null) {
        if (this.basicBoundaryRes.geometry) {
          geometry = this.basicBoundaryRes.geometry;
        }
      }
    }
    else if (this.activetab == 'poi') {
      if (this.poiBoundaryRes != null) {
        if (this.poiBoundaryRes.geometry) {
          geometry = this.poiBoundaryRes.geometry;
          let tmpHtml = '<div class="accordion" id="accordiondemo">';
          for (var i = 0; i < this.poiBoundaryRes.poiList.length; i++) {
            tmpHtml += '<div class="card">';
            tmpHtml += '<div class="card-header" id="poi_head' + i + '">';
            tmpHtml += '<h5 class="mb-0">';
            tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#poi_content' + i + '" aria-controls="#poi_content' + i + '"';
            if (i != 0) {
              tmpHtml += ' aria-expanded="false"';
            }
            else {
              tmpHtml += ' aria-expanded="true"';
            }
            tmpHtml += '>' + this.poiBoundaryRes.poiList[i].name + '</button></h5></div>';
            tmpHtml += '<div id="poi_content' + i + '" class="collapse';
            if (i == 0) {
              tmpHtml += ' show';
            }
            tmpHtml += '" aria-labelledby="poi_head' + i + '" data-parent="#accordiondemo"><div class="card-body">';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td colspan="2"><div class="resultKey">Address</div><div class="resultAccVal">' + this.poiBoundaryRes.poiList[i].contactDetails.address.formattedAddress + '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">Category code</div><div class="resultAccVal">' + this.poiBoundaryRes.poiList[i].poiClassification.category.categoryCode + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Class</div><div class="resultAccVal">' + this.poiBoundaryRes.poiList[i].poiClassification.category.class + '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">SIC code</div><div class="resultAccVal">' + this.poiBoundaryRes.poiList[i].poiClassification.sic.sicCode + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Description</div><div class="resultAccVal">' + this.poiBoundaryRes.poiList[i].poiClassification.sic.sicCodeDescription + '</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '</div></div></div>';
          }
          tmpHtml += '</div>';
          $('#geoZonePoiResult').html(tmpHtml);
        }
      }
    }
    if (geometry) {
      if (geometry.type == 'MultiPolygon') {
        for (let i = 0; i < geometry.coordinates.length; i++) {
          for (let j = 0; j < geometry.coordinates[i].length; j++) {
            for (let k = 0; k < geometry.coordinates[i][j].length; k++) {
              locationsArr.push([geometry.coordinates[i][j][k][1], geometry.coordinates[i][j][k][0]]);
            }
          }
        }
      }
      else {
        for (let i = 0; i < geometry.coordinates.length; i++) {
          for (let j = 0; j < geometry.coordinates[i].length; j++) {
            locationsArr.push([geometry.coordinates[i][j][1], geometry.coordinates[i][j][0]]);
          }
        }
      }
      this.polygon = L.geoJSON(geometry as any).addTo(this.map);
      this.map.fitBounds(locationsArr);
    }
  };
}
