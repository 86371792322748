import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';
import { UserService } from '../service/user.service';

@Component({
  selector: 'routing-api',
  templateUrl: './routingapi.component.html'
})
export class RoutingApiComponent implements OnInit {
  public rbaParamLink = '';
  public rblParamLink = '';
  public tcmbaParamLink = '';
  public tcmblParamLink = '';
  public rba_oip: boolean = false;
  public rba_returnDistance: boolean = true;
  public rba_returnTime: boolean = true;
  public rba_primaryNameOnly: boolean = false;
  public rba_majorRoads: boolean = false;
  public rba_returnDirectionGeometry: boolean = false;
  public rba_returnIntermediatePoints:boolean =false;
  public rbl_oip: boolean = false;
  public rbl_returnDistance: boolean = true;
  public rbl_returnTime: boolean = true;
  public rbl_primaryNameOnly: boolean = false;
  public rbl_majorRoads: boolean = false;
  public rbl_returnDirectionGeometry: boolean = false;
  public rbl_returnIntermediatePoints: boolean = false;
  public tcmba_returnDistance: boolean = true;
  public tcmba_returnTime: boolean = true;
  public tcmba_majorRoads: boolean = false;
  public tcmba_returnOptimalRoutesOnly: boolean = true;
  public tcmbl_returnDistance: boolean = true;
  public tcmbl_returnTime: boolean = true;
  public tcmbl_majorRoads: boolean = false;
  public tcmbl_returnOptimalRoutesOnly: boolean = true;
  public rbaCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public tcmbaCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  routeCountries: any[];
  filteredRouteCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getRoutingCountries().subscribe(
      success => {
        this.routeCountries = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'routing api, route navigation, travel directions, route optimization, route by address, historical traffic, distance matrix, vehicle routing, logistics routing' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else{
      this.setParamLink(docsURL);
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  filterRouteCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredRouteCountries = filtered;
  };
  setParamLink = function (docsURL) {
    this.rbaParamLink = docsURL + 'Routing/Route/byaddress/query_param.html';
    this.rblParamLink = docsURL + 'Routing/Route/bylocation/query_param.html';
    this.tcmbaParamLink = docsURL + 'Routing/TravelCostMatrix/byaddress/query_param.html';
    this.tcmblParamLink = docsURL + 'Routing/TravelCostMatrix/bylocation/query_param.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_routeByAddrRes':
        this.get_routeByAddrUri = "";
        this.get_routeByAddrRes = null;
        this.get_routeByAddrResStatus = "";
        this.get_routeByAddrResType = "";
        break;
      case 'get_routeByLocRes':
        this.get_routeByLocUri = "";
        this.get_routeByLocRes = null;
        this.get_routeByLocResStatus = "";
        this.get_routeByLocResType = "";
        break;
      case 'get_costByAddrRes':
        this.get_costByAddrUri = "";
        this.get_costByAddrRes = null;
        this.get_costByAddrResStatus = "";
        this.get_costByAddrResType = "";
        break;
      case 'get_costByLocRes':
        this.get_costByLocUri = "";
        this.get_costByLocRes = null;
        this.get_costByLocResStatus = "";
        this.get_costByLocResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    switch(nbsOtpPrmFrm){
      case 'rbaOtpPrmFrm':
        this.geoRouteTryApiRouteByAddrdirectionsStyle = '';
        this.geoRouteTryApiRouteByAddrsegmentGeometryStyle = '';
        this.geoRouteTryApiRouteByAddrdestinationSrs = '';
        this.geoRouteTryApiRouteByAddroptimizeBy = '';
        this.geoRouteTryApiRouteByAddrdistanceUnit = '';
        this.geoRouteTryApiRouteByAddrtimeUnit = '';
        this.geoRouteTryApiRouteByAddrlanguage = '';
        this.geoRouteTryApiRouteByAddrhistoricTrafficTimeBucket = '';
        this.geoRouteTryApiRouteByAddrUseCvr = '';
        this.geoRouteTryApiRouteByAddrLooseningBarrierRestrictions = '';
        this.geoRouteTryApiRouteByAddrVehicleType = '';
        this.geoRouteTryApiRouteByAddrWeight = '';
        this.geoRouteTryApiRouteByAddrWeightUnit = '';
        this.geoRouteTryApiRouteByAddrHeight = '';
        this.geoRouteTryApiRouteByAddrHeightUnit = '';
        this.geoRouteTryApiRouteByAddrlength = '';
        this.geoRouteTryApiRouteByAddrlengthUnit = '';
        this.geoRouteTryApiRouteByAddrwidth = '';
        this.geoRouteTryApiRouteByAddrWidthUnit = '';
        break;
      case 'rblOtpPrmFrm':
        this.geoRouteTryApiRouteByLocdirectionsStyle = '';
        this.geoRouteTryApiRouteByLocsegmentGeometryStyle = '';
        this.geoRouteTryApiRouteByLocdestinationSrs = '';
        this.geoRouteTryApiRouteByLocoptimizeBy = '';
        this.geoRouteTryApiRouteByLocdistanceUnit = '';
        this.geoRouteTryApiRouteByLoctimeUnit = '';
        this.geoRouteTryApiRouteByLoclanguage = '';
        this.geoRouteTryApiRouteByLochistoricTrafficTimeBucket = '';
        this.geoRouteTryApiRouteByLocUseCvr = '';
        this.geoRouteTryApiRouteByLocLooseningBarrierRestrictions = '';
        this.geoRouteTryApiRouteByLocVehicleType = '';
        this.geoRouteTryApiRouteByLocWeight = '';
        this.geoRouteTryApiRouteByLocWeightUnit = '';
        this.geoRouteTryApiRouteByLocHeight = '';
        this.geoRouteTryApiRouteByLocHeightUnit = '';
        this.geoRouteTryApiRouteByLoclength = '';
        this.geoRouteTryApiRouteByLoclengthUnit = '';
        this.geoRouteTryApiRouteByLocwidth = '';
        this.geoRouteTryApiRouteByLocWidthUnit = '';
        break;
      case 'tcmbaOtpPrmFrm':
        this.geoRouteTryApiTCMByAddrdestinationSrs = '';
        this.geoRouteTryApiTCMByAddroptimizeBy = '';
        this.geoRouteTryApiTCMByAddrdistanceUnit = '';
        this.geoRouteTryApiTCMByAddrtimeUnit = '';
        this.geoRouteTryApiTCMByAddrhistoricTrafficTimeBucket = '';
        this.geoRouteTryApiTCMByAddrUseCvr = '';
        this.geoRouteTryApiTCMByAddrLooseningBarrierRestrictions = '';
        this.geoRouteTryApiTCMByAddrVehicleType = '';
        this.geoRouteTryApiTCMByAddrWeight = '';
        this.geoRouteTryApiTCMByAddrWeightUnit = '';
        this.geoRouteTryApiTCMByAddrHeight = '';
        this.geoRouteTryApiTCMByAddrHeightUnit = '';
        this.geoRouteTryApiTCMByAddrlength = '';
        this.geoRouteTryApiTCMByAddrlengthUnit = '';
        this.geoRouteTryApiTCMByAddrwidth = '';
        this.geoRouteTryApiTCMByAddrWidthUnit = '';
        break;
      case 'tcmblOtpPrmFrm':
        this.geoRouteTryApiTCMByLocdestinationSrs = '';
        this.geoRouteTryApiTCMByLocoptimizeBy = '';
        this.geoRouteTryApiTCMByLocdistanceUnit = '';
        this.geoRouteTryApiTCMByLoctimeUnit = '';
        this.geoRouteTryApiTCMByLochistoricTrafficTimeBucket = '';
        this.geoRouteTryApiTCMByLocUseCvr = '';
        this.geoRouteTryApiTCMByLocLooseningBarrierRestrictions = '';
        this.geoRouteTryApiTCMByLocVehicleType = '';
        this.geoRouteTryApiTCMByLocWeight = '';
        this.geoRouteTryApiTCMByLocWeightUnit = '';
        this.geoRouteTryApiTCMByLocHeight = '';
        this.geoRouteTryApiTCMByLocHeightUnit = '';
        this.geoRouteTryApiTCMByLoclength = '';
        this.geoRouteTryApiTCMByLoclengthUnit = '';
        this.geoRouteTryApiTCMByLocwidth = '';
        this.geoRouteTryApiTCMByLocWidthUnit = '';
        break;
    }
  };
  public geoRouteTryApiRouteByAddrstartAddress = '4750 Walnut St, Boulder, CO';
  public geoRouteTryApiRouteByAddrendAddress = '2935 Broadbridge Ave, Stratford, CT';
  public geoRouteTryApiRouteByAddrintermediateAddresses = '';
  public geoRouteTryApiRouteByAddrdb = 'driving';
  public geoRouteTryApiRouteByAddrdirectionsStyle = 'None';
  public geoRouteTryApiRouteByAddrsegmentGeometryStyle = 'none';
  public geoRouteTryApiRouteByAddrdestinationSrs = 'epsg:4326';
  public geoRouteTryApiRouteByAddroptimizeBy = 'time';
  public geoRouteTryApiRouteByAddrdistanceUnit = 'm';
  public geoRouteTryApiRouteByAddrtimeUnit = 'min';
  public geoRouteTryApiRouteByAddrlanguage = 'en';
  public geoRouteTryApiRouteByAddrhistoricTrafficTimeBucket = 'None';
  public geoRouteTryApiRouteByAddrUseCvr = 'N';
  public geoRouteTryApiRouteByAddrLooseningBarrierRestrictions = 'Y';
  public geoRouteTryApiRouteByAddrVehicleType = 'ALL';
  public geoRouteTryApiRouteByAddrWeight = '10';
  public geoRouteTryApiRouteByAddrWeightUnit = 'kg';
  public geoRouteTryApiRouteByAddrHeight = '';
  public geoRouteTryApiRouteByAddrHeightUnit = 'ft';
  public geoRouteTryApiRouteByAddrlength = '';
  public geoRouteTryApiRouteByAddrlengthUnit = 'ft';
  public geoRouteTryApiRouteByAddrwidth = '';
  public geoRouteTryApiRouteByAddrWidthUnit = 'ft';
  public get_routeByAddrUri = "";
  public get_routeByAddrRes = null;
  public get_routeByAddrResStatus = "";
  public get_routeByAddrResType = "";
  tryApiGeoRouteRouteByAddr = function (btnId) {
    var startAddress = this.geoRouteTryApiRouteByAddrstartAddress;
    var endAddress = this.geoRouteTryApiRouteByAddrendAddress;
    var intermediateAddresses = this.geoRouteTryApiRouteByAddrintermediateAddresses;
    var db = this.geoRouteTryApiRouteByAddrdb;
    var directionsStyle = this.geoRouteTryApiRouteByAddrdirectionsStyle;
    var segmentGeometryStyle = this.geoRouteTryApiRouteByAddrsegmentGeometryStyle;
    var destinationSrs = this.geoRouteTryApiRouteByAddrdestinationSrs;
    var optimizeBy = this.geoRouteTryApiRouteByAddroptimizeBy;
    var distanceUnit = this.geoRouteTryApiRouteByAddrdistanceUnit;
    var timeUnit = this.geoRouteTryApiRouteByAddrtimeUnit;
    var language = this.geoRouteTryApiRouteByAddrlanguage;
    var historicTrafficTimeBucket = this.geoRouteTryApiRouteByAddrhistoricTrafficTimeBucket;
    var useCvr = this.geoRouteTryApiRouteByAddrUseCvr;
    var looseningBarrierRestrictions = this.geoRouteTryApiRouteByAddrLooseningBarrierRestrictions;
    var vehicleType = this.geoRouteTryApiRouteByAddrVehicleType;
    var weight = this.geoRouteTryApiRouteByAddrWeight;
    var weightUnit = this.geoRouteTryApiRouteByAddrWeightUnit;
    var height = this.geoRouteTryApiRouteByAddrHeight;
    var heightUnit = this.geoRouteTryApiRouteByAddrHeightUnit;
    var length = this.geoRouteTryApiRouteByAddrlength;
    var lengthUnit = this.geoRouteTryApiRouteByAddrlengthUnit;
    var width = this.geoRouteTryApiRouteByAddrwidth;
    var widthUnit = this.geoRouteTryApiRouteByAddrWidthUnit;
    var tmpData = {
      startAddress: startAddress,
      endAddress: endAddress,
      dataType: 'json'
    };
    if (intermediateAddresses) {
      tmpData['intermediateAddresses'] = intermediateAddresses;
    }
    if (this.rbaCountry != '') {
      if (this.rbaCountry.code) {
        tmpData['country'] = this.rbaCountry.code;
      }
      else {
        tmpData['country'] = this.rbaCountry;
      }
    }
    if (db) {
      tmpData['db'] = db;
    }
    if (directionsStyle) {
      tmpData['directionsStyle'] = directionsStyle;
    }
    if (segmentGeometryStyle) {
      tmpData['segmentGeometryStyle'] = segmentGeometryStyle;
    }
    if (destinationSrs) {
      tmpData['destinationSrs'] = destinationSrs;
    }
    tmpData['oip'] = this.rba_oip;
    if (optimizeBy) {
      tmpData['optimizeBy'] = optimizeBy;
    }
    tmpData['returnDistance'] = this.rba_returnDistance;
    if (distanceUnit) {
      tmpData['distanceUnit'] = distanceUnit;
    }
    tmpData['returnTime'] = this.rba_returnTime;
    if (timeUnit) {
      tmpData['timeUnit'] = timeUnit;
    }
    if (language) {
      tmpData['language'] = language;
    }
    tmpData['primaryNameOnly'] = this.rba_primaryNameOnly;
    tmpData['majorRoads'] = this.rba_majorRoads;
    tmpData['returnDirectionGeometry'] = this.rba_returnDirectionGeometry;
    tmpData['returnIntermediatePoints'] = this.rba_returnIntermediatePoints;
    if (historicTrafficTimeBucket) {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (useCvr) {
      tmpData['useCvr'] = useCvr;
    } if (looseningBarrierRestrictions) {
      tmpData['looseningBarrierRestrictions'] = looseningBarrierRestrictions;
      } if (vehicleType) {
      tmpData['vehicleType'] = vehicleType;
    } if (weight) {
      tmpData['weight'] = weight;
    } if (weightUnit) {
      tmpData['weightUnit'] = weightUnit;
    } if (height) {
      tmpData['height'] = height;
    } if (heightUnit) {
      tmpData['heightUnit'] = heightUnit;
    } if (length) {
      tmpData['length'] = length;
    }
    if (lengthUnit) {
      tmpData['lengthUnit'] = lengthUnit;
    } if (width) {
      tmpData['width'] = width;
      tmpData['length'] = length;
    } if (widthUnit) {
      tmpData['widthUnit'] = widthUnit;
    }
    if (startAddress != '' && endAddress != '') {
      var methodName = '/tryApiGeoRouteRouteByAddr';
      let requestUri = this.liapiservice.getCompleteUri('routing', 'Route By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_routeByAddrUri = requestUri;
          this.get_routeByAddrResType = 'json';
          if(this.get_routeByAddrResStatus.includes('429')){
            this.get_routeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_routeByAddrResStatus = '200 success';
            this.get_routeByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_routeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_routeByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRouteTryApiRouteByLocstartPoint = '77.5,38.8,epsg:4326';
  public geoRouteTryApiRouteByLocendPoint = '77.5,39.8,epsg:4326';
  public geoRouteTryApiRouteByLocintermediatePoints = '';
  public geoRouteTryApiRouteByLocdb = 'driving';
  public geoRouteTryApiRouteByLocdirectionsStyle = 'None';
  public geoRouteTryApiRouteByLocsegmentGeometryStyle = 'none';
  public geoRouteTryApiRouteByLocdestinationSrs = 'epsg:4326';
  public geoRouteTryApiRouteByLocoptimizeBy = 'time';
  public geoRouteTryApiRouteByLocdistanceUnit = 'm';
  public geoRouteTryApiRouteByLoctimeUnit = 'min';
  public geoRouteTryApiRouteByLoclanguage = 'en';
  public geoRouteTryApiRouteByLochistoricTrafficTimeBucket = 'None';
  public geoRouteTryApiRouteByLocUseCvr = 'N';
  public geoRouteTryApiRouteByLocLooseningBarrierRestrictions = 'Y';
  public geoRouteTryApiRouteByLocVehicleType = 'ALL';
  public geoRouteTryApiRouteByLocWeight = '';
  public geoRouteTryApiRouteByLocWeightUnit = 'kg';
  public geoRouteTryApiRouteByLocHeight = '';
  public geoRouteTryApiRouteByLocHeightUnit = 'ft';
  public geoRouteTryApiRouteByLoclength = '';
  public geoRouteTryApiRouteByLoclengthUnit = 'ft';
  public geoRouteTryApiRouteByLocwidth = '';
  public geoRouteTryApiRouteByLocWidthUnit = 'ft';
  public get_routeByLocUri = "";
  public get_routeByLocRes = null;
  public get_routeByLocResStatus = "";
  public get_routeByLocResType = "";
  tryApiGeoRouteRouteByLoc = function (btnId) {
    var startPoint = this.geoRouteTryApiRouteByLocstartPoint;
    var endPoint = this.geoRouteTryApiRouteByLocendPoint;
    var intermediatePoints = this.geoRouteTryApiRouteByLocintermediatePoints;
    var db = this.geoRouteTryApiRouteByLocdb;
    var directionsStyle = this.geoRouteTryApiRouteByLocdirectionsStyle;
    var segmentGeometryStyle = this.geoRouteTryApiRouteByLocsegmentGeometryStyle;
    var destinationSrs = this.geoRouteTryApiRouteByLocdestinationSrs;
    var optimizeBy = this.geoRouteTryApiRouteByLocoptimizeBy;
    var distanceUnit = this.geoRouteTryApiRouteByLocdistanceUnit;
    var timeUnit = this.geoRouteTryApiRouteByLoctimeUnit;
    var language = this.geoRouteTryApiRouteByLoclanguage;
    var historicTrafficTimeBucket = this.geoRouteTryApiRouteByLochistoricTrafficTimeBucket;
    var useCvr = this.geoRouteTryApiRouteByLocUseCvr;
    var looseningBarrierRestrictions = this.geoRouteTryApiRouteByLocLooseningBarrierRestrictions;
    var vehicleType = this.geoRouteTryApiRouteByLocVehicleType;
    var weight = this.geoRouteTryApiRouteByLocWeight;
    var weightUnit = this.geoRouteTryApiRouteByLocWeightUnit;
    var height = this.geoRouteTryApiRouteByLocHeight;
    var heightUnit = this.geoRouteTryApiRouteByLocHeightUnit;
    var length = this.geoRouteTryApiRouteByLoclength;
    var lengthUnit = this.geoRouteTryApiRouteByLoclengthUnit;
    var width = this.geoRouteTryApiRouteByLocwidth;
    var widthUnit = this.geoRouteTryApiRouteByLocWidthUnit;

    var tmpData = {
      startPoint: startPoint,
      endPoint: endPoint,
      dataType: 'json'
    };
    if (intermediatePoints) {
      tmpData['intermediatePoints'] = intermediatePoints;
    }
    if (db) {
      tmpData['db'] = db;
    }
    if (directionsStyle) {
      tmpData['directionsStyle'] = directionsStyle;
    }
    if (segmentGeometryStyle) {
      tmpData['segmentGeometryStyle'] = segmentGeometryStyle;
    }
    if (destinationSrs) {
      tmpData['destinationSrs'] = destinationSrs;
    }
    tmpData['oip'] = this.rbl_oip;
    if (optimizeBy) {
      tmpData['optimizeBy'] = optimizeBy;
    }
    tmpData['returnDistance'] = this.rbl_returnDistance;
    if (distanceUnit) {
      tmpData['distanceUnit'] = distanceUnit;
    }
    tmpData['returnTime'] = this.rbl_returnTime;
    if (timeUnit) {
      tmpData['timeUnit'] = timeUnit;
    }
    if (language) {
      tmpData['language'] = language;
    }
    tmpData['primaryNameOnly'] = this.rbl_primaryNameOnly;
    tmpData['majorRoads'] = this.rbl_majorRoads;
    tmpData['returnDirectionGeometry'] = this.rbl_returnDirectionGeometry;
    tmpData['returnIntermediatePoints'] = this.rbl_returnIntermediatePoints;
    if (historicTrafficTimeBucket) {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (useCvr) {
      tmpData['useCvr'] = useCvr;
    } if (looseningBarrierRestrictions) {
      tmpData['looseningBarrierRestrictions'] = looseningBarrierRestrictions;
    } if (vehicleType) {
      tmpData['vehicleType'] = vehicleType;
    } if (weight) {
      tmpData['weight'] = weight;
    } if (weightUnit) {
      tmpData['weightUnit'] = weightUnit;
    } if (height) {
      tmpData['height'] = height;
    } if (heightUnit) {
      tmpData['heightUnit'] = heightUnit;
    } if (length) {
      tmpData['length'] = length;
    }
    if (lengthUnit) {
      tmpData['lengthUnit'] = lengthUnit;
    } if (width) {
      tmpData['width'] = width;
    } if (length) {
      tmpData['length'] = length;
    } if (widthUnit) {
      tmpData['widthUnit'] = widthUnit;
    }
    if (startPoint != '' && endPoint != '') {
      var methodName = '/tryApiGeoRouteRouteByLoc';
      let requestUri = this.liapiservice.getCompleteUri('routing', 'Route By Location', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_routeByLocUri = requestUri;
          this.get_routeByLocResType = 'json';
          if(this.get_routeByLocResStatus.includes('429')){
            this.get_routeByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_routeByLocResStatus = '200 success';
            this.get_routeByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_routeByLocResStatus = `${error.status} ${error.statusText}`;
              this.get_routeByLocrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRouteTryApiTCMByAddrstartAddresses = '4750 Walnut St, Boulder, CO';
  public geoRouteTryApiTCMByAddrendAddresses = '2935 Broadbridge Ave, Stratford, CT';
  public geoRouteTryApiTCMByAddrdb = 'driving';
  public geoRouteTryApiTCMByAddrdirectionsStyle = 'None';
  public geoRouteTryApiTCMByAddrdestinationSrs = 'epsg:4326';
  public geoRouteTryApiTCMByAddroptimizeBy = 'time';
  public geoRouteTryApiTCMByAddrdistanceUnit = 'm';
  public geoRouteTryApiTCMByAddrtimeUnit = 'min';
  public geoRouteTryApiTCMByAddrhistoricTrafficTimeBucket = 'None';
  public geoRouteTryApiTCMByAddrUseCvr = 'N';
  public geoRouteTryApiTCMByAddrLooseningBarrierRestrictions = 'Y';
  public geoRouteTryApiTCMByAddrVehicleType = 'ALL';
  public geoRouteTryApiTCMByAddrWeight = '';
  public geoRouteTryApiTCMByAddrWeightUnit = 'kg';
  public geoRouteTryApiTCMByAddrHeight = '';
  public geoRouteTryApiTCMByAddrHeightUnit = 'ft';
  public geoRouteTryApiTCMByAddrlength = '';
  public geoRouteTryApiTCMByAddrlengthUnit = 'ft';
  public geoRouteTryApiTCMByAddrwidth = '';
  public geoRouteTryApiTCMByAddrWidthUnit = 'ft';
  public get_costByAddrUri = "";
  public get_costByAddrRes = null;
  public get_costByAddrResStatus = "";
  public get_costByAddrResType = "";
  tryApiGeoRouteTCMByAddr = function (btnId) {
    var startAddresses = this.geoRouteTryApiTCMByAddrstartAddresses;
    var endAddresses = this.geoRouteTryApiTCMByAddrendAddresses;
    var db = this.geoRouteTryApiTCMByAddrdb;
    var destinationSrs = this.geoRouteTryApiTCMByAddrdestinationSrs;
    var optimizeBy = this.geoRouteTryApiTCMByAddroptimizeBy;
    var distanceUnit = this.geoRouteTryApiTCMByAddrdistanceUnit;
    var timeUnit = this.geoRouteTryApiTCMByAddrtimeUnit;
    var historicTrafficTimeBucket = this.geoRouteTryApiTCMByAddrhistoricTrafficTimeBucket;
    var useCvr = this.geoRouteTryApiTCMByAddrUseCvr;
    var looseningBarrierRestrictions = this.geoRouteTryApiTCMByAddrLooseningBarrierRestrictions;
    var vehicleType = this.geoRouteTryApiTCMByAddrVehicleType;
    var weight = this.geoRouteTryApiTCMByAddrWeight;
    var weightUnit = this.geoRouteTryApiTCMByAddrWeightUnit;
    var height = this.geoRouteTryApiTCMByAddrHeight;
    var heightUnit = this.geoRouteTryApiTCMByAddrHeightUnit;
    var length = this.geoRouteTryApiTCMByAddrlength;
    var lengthUnit = this.geoRouteTryApiTCMByAddrlengthUnit;
    var width = this.geoRouteTryApiTCMByAddrwidth;
    var widthUnit = this.geoRouteTryApiTCMByAddrWidthUnit;
    var tmpData = {
      startAddresses: startAddresses,
      endAddresses: endAddresses,
      dataType: 'json'
    };
    if (this.tcmbaCountry != '') {
      if (this.tcmbaCountry.code) {
        tmpData['country'] = this.tcmbaCountry.code;
      }
      else {
        tmpData['country'] = this.tcmbaCountry;
      }
    }
    if (db) {
      tmpData['db'] = db;
    }
    if (destinationSrs) {
      tmpData['destinationSrs'] = destinationSrs;
    }
    if (optimizeBy) {
      tmpData['optimizeBy'] = optimizeBy;
    }
    tmpData['returnDistance'] = this.tcmba_returnDistance;
    if (distanceUnit) {
      tmpData['distanceUnit'] = distanceUnit;
    }
    tmpData['returnTime'] = this.tcmba_returnTime;
    if (timeUnit) {
      tmpData['timeUnit'] = timeUnit;
    }
    tmpData['majorRoads'] = this.tcmba_majorRoads;
    tmpData['returnOptimalRoutesOnly'] = this.tcmba_returnOptimalRoutesOnly;
    if (historicTrafficTimeBucket) {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (useCvr) {
      tmpData['useCvr'] = useCvr;
    }
    if (looseningBarrierRestrictions) {
      tmpData['looseningBarrierRestrictions'] = looseningBarrierRestrictions;
    }
    if (vehicleType) {
      tmpData['vehicleType'] = vehicleType;
    } if (weight) {
      tmpData['weight'] = weight;
    } if (weightUnit) {
      tmpData['weightUnit'] = weightUnit;
    } if (height) {
      tmpData['height'] = height;
    } if (heightUnit) {
      tmpData['heightUnit'] = heightUnit;
    } if (length) {
      tmpData['length'] = length;
    }
    if (lengthUnit) {
      tmpData['lengthUnit'] = lengthUnit;
    } if (width) {
      tmpData['width'] = width;
    } if (length) {
      tmpData['length'] = length;
    } if (widthUnit) {
      tmpData['widthUnit'] = widthUnit;
    }
    if (startAddresses != '' && endAddresses != '') {
      var methodName = '/tryApiGeoRouteTCMByAddr';
      let requestUri = this.liapiservice.getCompleteUri('routing', 'Travel Cost Matrix By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_costByAddrUri = requestUri;
          this.get_costByAddrResType = 'json';
          if(this.get_costByAddrResStatus.includes('429')){
            this.get_costByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_costByAddrResStatus = '200 success';
            this.get_costByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_costByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_costByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRouteTryApiTCMByLocstartPoints = '77.5,38.8,epsg:4326';
  public geoRouteTryApiTCMByLocendPoints = '77.5,39.8,epsg:4326';
  public geoRouteTryApiTCMByLocdb = 'driving';
  public geoRouteTryApiTCMByLocdestinationSrs = 'epsg:4326';
  public geoRouteTryApiTCMByLocoptimizeBy = 'time';
  public geoRouteTryApiTCMByLocdistanceUnit = 'm';
  public geoRouteTryApiTCMByLoctimeUnit = 'min';
  public geoRouteTryApiTCMByLochistoricTrafficTimeBucket = 'None';
  public geoRouteTryApiTCMByLocUseCvr = 'N';
  public geoRouteTryApiTCMByLocLooseningBarrierRestrictions = 'Y';
  public geoRouteTryApiTCMByLocVehicleType = 'ALL';
  public geoRouteTryApiTCMByLocWeight = '';
  public geoRouteTryApiTCMByLocWeightUnit = 'kg';
  public geoRouteTryApiTCMByLocHeight = '';
  public geoRouteTryApiTCMByLocHeightUnit = 'ft';
  public geoRouteTryApiTCMByLoclength = '';
  public geoRouteTryApiTCMByLoclengthUnit = 'ft';
  public geoRouteTryApiTCMByLocwidth = '';
  public geoRouteTryApiTCMByLocWidthUnit = 'ft';
  public get_costByLocUri = "";
  public get_costByLocRes = null;
  public get_costByLocResStatus = "";
  public get_costByLocResType = "";
  tryApiGeoRouteTCMByLoc = function (btnId) {
    var startPoints = this.geoRouteTryApiTCMByLocstartPoints;
    var endPoints = this.geoRouteTryApiTCMByLocendPoints;
    var db = this.geoRouteTryApiTCMByLocdb;
    var destinationSrs = this.geoRouteTryApiTCMByLocdestinationSrs;
    var optimizeBy = this.geoRouteTryApiTCMByLocoptimizeBy;
    var distanceUnit = this.geoRouteTryApiTCMByLocdistanceUnit;
    var timeUnit = this.geoRouteTryApiTCMByLoctimeUnit;
    var historicTrafficTimeBucket = this.geoRouteTryApiTCMByLochistoricTrafficTimeBucket;
    var useCvr = this.geoRouteTryApiTCMByLocUseCvr;
    var looseningBarrierRestrictions = this.geoRouteTryApiTCMByLocLooseningBarrierRestrictions;
    var vehicleType = this.geoRouteTryApiTCMByLocVehicleType;
    var weight = this.geoRouteTryApiTCMByLocWeight;
    var weightUnit = this.geoRouteTryApiTCMByLocWeightUnit;
    var height = this.geoRouteTryApiTCMByLocHeight;
    var heightUnit = this.geoRouteTryApiTCMByLocHeightUnit;
    var length = this.geoRouteTryApiTCMByLoclength;
    var lengthUnit = this.geoRouteTryApiTCMByLoclengthUnit;
    var width = this.geoRouteTryApiTCMByLocwidth;
    var widthUnit = this.geoRouteTryApiTCMByLocWidthUnit;

    var tmpData = {
      startPoints: startPoints,
      endPoints: endPoints,
      dataType: 'json'
    };
    if (db) {
      tmpData['db'] = db;
    }
    if (destinationSrs) {
      tmpData['destinationSrs'] = destinationSrs;
    }
    if (optimizeBy) {
      tmpData['optimizeBy'] = optimizeBy;
    }
    tmpData['returnDistance'] = this.tcmbl_returnDistance;
    if (distanceUnit) {
      tmpData['distanceUnit'] = distanceUnit;
    }
    tmpData['returnTime'] = this.tcmbl_returnTime;
    if (timeUnit) {
      tmpData['timeUnit'] = timeUnit;
    }
    tmpData['majorRoads'] = this.tcmbl_majorRoads;
    tmpData['returnOptimalRoutesOnly'] = this.tcmbl_returnOptimalRoutesOnly;
    if (historicTrafficTimeBucket) {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (useCvr) {
      tmpData['useCvr'] = useCvr;
    } if (looseningBarrierRestrictions) {
      tmpData['looseningBarrierRestrictions'] = looseningBarrierRestrictions;
    } if (vehicleType) {
      tmpData['vehicleType'] = vehicleType;
    } if (weight) {
      tmpData['weight'] = weight;
    } if (weightUnit) {
      tmpData['weightUnit'] = weightUnit;
    } if (height) {
      tmpData['height'] = height;
    } if (heightUnit) {
      tmpData['heightUnit'] = heightUnit;
    } if (length) {
      tmpData['length'] = length;
    }
    if (lengthUnit) {
      tmpData['lengthUnit'] = lengthUnit;
    } if (width) {
      tmpData['width'] = width;
    } if (length) {
      tmpData['length'] = length;
    } if (widthUnit) {
      tmpData['widthUnit'] = widthUnit;
    }
    if (startPoints != '' && endPoints != '') {
      var methodName = '/tryApiGeoRouteTCMByLoc';
      let requestUri = this.liapiservice.getCompleteUri('routing', 'Travel Cost Matrix By Location', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_costByLocUri = requestUri;
          this.get_costByLocResType = 'json';
          if(this.get_costByLocResStatus.includes('429')){
            this.get_costByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_costByLocResStatus = '200 success';
            this.get_costByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_costByLocResStatus = `${error.status} ${error.statusText}`;
              this.get_costByLocrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
