import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'psap-demo',
  templateUrl: './psapdemo.component.html'
})
export class PsapDemoComponent implements OnInit {
  public searchedAddr = "";
  public searchAddress = "";
  public activetab = "psap";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) {};
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
  };
  showSearchForm = function () {
    this.searchedAddr = "";
  };
  geo911DemoSearchAddress = function () {
    this.searchedAddr = this.searchAddress;
    var country = 'USA';
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    $('#geo911DemoSearchError').hide();
    $('#geo911PSAPError').hide();
    $('#geo911PSAPResult').html('');
    $('#geo911AHJPSAPError').hide();
    $('#geo911AHJPSAPResult').html('');
    if (this.searchAddress != '') {
      this.geoApisGetLocationGeocode(this.searchAddress, country);
    }
    else {
      $('#geo911DemoSearchError').html('Address cannot be blank.');
      $('#geo911DemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var latitude = success.candidates[0].geometry.coordinates[1];
          var longitude = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(latitude, longitude), 14);
          this.newMarker = L.marker([latitude, longitude], this.markerIcon).addTo(this.map);
          this.sampleDemoGeo911PSAPByLocation(latitude, longitude);
          this.sampleDemoGeo911AHJPSAPByLocation(latitude, longitude);
        }
        else {
          $('#geo911PSAPError').hide();
          $('#geo911PSAPResult').html('');
          $('#geo911AHJPSAPError').hide();
          $('#geo911AHJPSAPResult').html('');
          $('#geo911DemoSearchError').html('No information available for this address.');
          $('#geo911DemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeo911PSAPByLocation = function (lat, lng) {
    $('#geo911PSAPError').hide();
    $('#geo911PSAPResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeo911PSAPByLoc', tmpData).subscribe(
      success => {
        if (success.agency) {
          var tmpHtml = '<h2 class="zeromargintop">PSAP Details</h2><h4 class="zeromargintop">' + success.agency + '</h4>';
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultVal">' + success.siteDetails.phone + '</div></td>';
          tmpHtml += '<td><div class="resultKey">Fax</div><div class="resultVal">' + success.siteDetails.fax + '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Coverage Area</div><div class="resultVal">' + success.coverage.area + '</div></td>';
          tmpHtml += '<td><div class="resultKey">Exceptions</div><div class="resultVal">' + success.coverage.exceptions + '</div></td></tr>';
          tmpHtml += '</table>';
          tmpHtml += '<h4 class="zeromargintop">Contact Person</h4>';
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Title</div><div class="resultVal">' + success.contactPerson.title + '</div></td>';
          tmpHtml += '<td><div class="resultKey">Name</div><div class="resultVal">' + success.contactPerson.prefix + ' ' + success.contactPerson.firstName + ' ' + success.contactPerson.lastName + '</div></td></tr>';
          tmpHtml += '</table>';
          tmpHtml += '<h4 class="zeromargintop">Address Detail</h4>';
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Address</div><div class="resultVal">' + success.mailingAddress.formattedAddress + '</div></td>';
          tmpHtml += '<td><div class="resultKey">Country</div><div class="resultVal">' + success.mailingAddress.country + '</div></td></tr>';
          tmpHtml += '</table>';
          $('#geo911PSAPResult').html(tmpHtml);
        }
        else {
          $('#geo911PSAPError').html('No information available for this location.');
          $('#geo911PSAPError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geo911PSAPError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geo911PSAPError').html(error.statusText);
        }
        $('#geo911PSAPError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geo911PSAPError').hide();
        }
      }
    );
  };
  sampleDemoGeo911AHJPSAPByLocation = function (lat, lng) {
    $('#geo911AHJPSAPError').hide();
    $('#geo911AHJPSAPResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeo911AHJByLoc', tmpData).subscribe(
      success => {
        if (success.psap || success.ahjs) {
          var flagPSAPdisplay = false;
          var tmpHtml = '<h2 class="zeromargintop padSide">AHJ &amp; PSAP Details</h2><div class="accordion" id="accordiondemo">';
          if (success.ahjs.ahjs.length != 0) {
            for (var i = 0; i < success.ahjs.ahjs.length; i++) {
              tmpHtml += '<div class="card">';
              tmpHtml += '<div class="card-header" id="ahj_head' + i + '">';
              tmpHtml += '<h5 class="mb-0">';
              tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content' + i + '" aria-controls="#ahj_content' + i + '"';
              if (i != 0) {
                tmpHtml += ' aria-expanded="false"';
              }
              else {
                tmpHtml += ' aria-expanded="true"';
              }
              tmpHtml += '>AHJ - ' + success.ahjs.ahjs[i].ahjType.toUpperCase() + '</button></h5></div>';
              tmpHtml += '<div id="ahj_content' + i + '" class="collapse';
              if (i == 0) {
                tmpHtml += ' show';
              }
              tmpHtml += '" aria-labelledby="ahj_head' + i + '" data-parent="#accordiondemo"><div class="card-body">';
              tmpHtml += '<div class="resultAccValFocus">' + success.ahjs.ahjs[i].agency + '</div>';
              tmpHtml += '<table class="table table-condensed table-unstyled">';
              tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultAccVal">' + success.ahjs.ahjs[i].phone + '</div></td>';
              tmpHtml += '<td><div class="resultKey">Coverage Area</div><div class="resultAccVal">' + success.ahjs.ahjs[i].coverage.area + '</div></td></tr>';
              tmpHtml += '<tr><td colspan="2"><div class="resultKey">Exceptions</div><div class="resultAccVal">' + success.ahjs.ahjs[i].coverage.exceptions + '</div></td></tr>';
              tmpHtml += '</table>';
              if (success.ahjs.ahjs[i].contactPerson.phone != '') {
                tmpHtml += '<div class="resultAccValFocus">Contact Person</div>';
                tmpHtml += '<table class="table table-condensed table-unstyled">';
                tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultAccVal">' + success.ahjs.ahjs[i].contactPerson.phone + '</div></td></tr>';
                tmpHtml += '</table>';
              }
              tmpHtml += '<div class="resultAccValFocus">Address Detail</div>';
              tmpHtml += '<table class="table table-condensed table-unstyled">';
              tmpHtml += '<tr><td><div class="resultKey">Address</div><div class="resultAccVal">' + success.ahjs.ahjs[i].mailingAddress.formattedAddress + '</div></td>';
              tmpHtml += '<td><div class="resultKey">Country</div><div class="resultAccVal">' + success.ahjs.ahjs[i].mailingAddress.country + '</div></td></tr>';
              tmpHtml += '</table>';
              tmpHtml += '</div></div></div>';
            }
          }
          else {
            flagPSAPdisplay = true;
            tmpHtml += '<div class="card">';
            tmpHtml += '<div class="card-header" id="ahj_head0"><h5 class="mb-0"><button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content0" aria-controls="#ahj_content0" aria-expanded="true">AHJ</button></h5></div>';
            tmpHtml += '<div id="ahj_content0" class="collapse show" aria-labelledby="ahj_head0" data-parent="#accordiondemo"><div class="card-body">';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div>No AHJ information available for this location.</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '</div></div></div>';
          }
          if (success.psap.agency) {
            tmpHtml += '<div class="card">';
            tmpHtml += '<div class="card-header" id="psap_head0">';
            tmpHtml += '<h5 class="mb-0">';
            tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#psap_content0" aria-controls="#psap_content0"';
            if (!flagPSAPdisplay) {
              tmpHtml += ' aria-expanded="false"';
            }
            else {
              tmpHtml += ' aria-expanded="true"';
            }
            tmpHtml += '>PSAP</button></h5></div>';
            tmpHtml += '<div id="psap_content0" class="collapse';
            if (flagPSAPdisplay) {
              tmpHtml += ' show';
            }
            tmpHtml += '" aria-labelledby="psap_head0" data-parent="#accordiondemo"><div class="card-body">';
            tmpHtml += '<div class="resultAccValFocus">' + success.psap.agency + '</div>';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultAccVal">' + success.psap.siteDetails.phone + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Fax</div><div class="resultAccVal">' + success.psap.siteDetails.fax + '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">Coverage Area</div><div class="resultAccVal">' + success.psap.coverage.area + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Exceptions</div><div class="resultAccVal">' + success.psap.coverage.exceptions + '</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '<div class="resultAccValFocus">Contact Person</div>';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div class="resultKey">Title</div><div class="resultAccVal">' + success.psap.contactPerson.title + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Name</div><div class="resultAccVal">' + success.psap.contactPerson.prefix + ' ' + success.psap.contactPerson.firstName + ' ' + success.psap.contactPerson.lastName + '</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '<div class="resultAccValFocus">Address Detail</div>';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div class="resultKey">Address</div><div class="resultAccVal">' + success.psap.mailingAddress.formattedAddress + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Country</div><div class="resultAccVal">' + success.psap.mailingAddress.country + '</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '</div></div></div>';
          }
          tmpHtml += '</div>';
          $('#geo911AHJPSAPResult').html(tmpHtml);
        }
        else {
          $('#geo911AHJPSAPError').html('No information available for this location.');
          $('#geo911AHJPSAPError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geo911AHJPSAPError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geo911AHJPSAPError').html(error.statusText);
        }
        $('#geo911AHJPSAPError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geo911AHJPSAPError').hide();
        }
      }
    );
  };
}
