import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';
import * as AppConfigs from '../app.constants';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent implements OnInit {
  public showSignUpBtn: boolean = false;
  public addProductBtn: boolean = false;
  public showUpgradeBtn: boolean = false;
  public currentPlan: any = "";
  public signUpURL: any = "";
  public hasPastDue: boolean = true;
  public hasFutSubs: boolean = true;
  public isValidEntitlement: boolean = true;
  public creditpricing: boolean = false;
  public showSignupModal: boolean = false;
  public showOveragesModal: boolean = false;
  public showOveragesNewModal: boolean = false;
  public contactLink = 'https://www.precisely.com/contact';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService, private userService: UserService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely api, geocoding api pricing, address validation pricing' }
    );
    let userDetails = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userDetails = success;
        this.showSignUpBtn = false;
        for (var i = 0; i < userDetails.entitlements.length; i++) {
          if (userDetails.entitlements[i].product == "LBS") {
            if(userDetails.entitlements[i].status == 'SUSPENDED' || userDetails.entitlements[i].status == 'CANCELLED'){
              this.isValidEntitlement = false;
            }
            this.currentPlan = userDetails.entitlements[i].plan;
            let recurlyAccountId = userDetails.entitlements[i].recurlyAccountId;
            if(recurlyAccountId){
              let planId = this.currentPlan;
              if (planId == 'LI_5K_Month_to_Month' || planId == 'LI_100K_12_Months' || planId == 'LI_10K_Month_to_Month' || planId == 'LI_25K_Annual' || planId == 'LI_5K_Annual') {
                this.getUserAccountDetails();
              }
              else{
                this.hasPastDue = false;
                this.hasFutSubs = false;
                this.checkAccount();
              }
            }
            else{
              //alert("User without recurly account id.");
              document.location.href = '/signout';
            }
          }
        }
        if (this.currentPlan == "") {
          this.addProductBtn = true;
        }
        else {
          this.showUpgradeBtn = true;
        }
      },
      error => {
        this.showSignUpBtn = true;
      }
    );
    this.resourceService.getSignupUrl().subscribe(
      success => {
        this.signUpURL = success.signUpURL;
      }
    );
  };
  checkAccount= function(){
    this.userService.getUserAccountDetailsById().subscribe(
      success => {},
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  signUpNow = function (planName) {
    let purlParam = this.activatedRoute.queryParams.getValue().purl;
    var signUpURL = this.signUpURL + "signup/software-apis?plan=" + planName;
    if(purlParam){
      signUpURL += "&purl=" + purlParam;
    }
    window.location.href = signUpURL;
  };
  addProduct = function (planName) {
    var addProductURL = this.signUpURL + "addproduct/software-apis?plan=" + planName;
    window.location.href = addProductURL;
  };
  upgradePlan = function (planName) {
    var upgradePlanURL = this.signUpURL + "upgrade/software-apis?plan=" + planName;
    window.location.href = upgradePlanURL;
  };
}
