import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import { UserService } from '../service/user.service';

@Component({
  selector: 'telecomm-api',
  templateUrl: './telecommapi.component.html'
})
export class TelecommApiComponent implements OnInit {
  public byAddrParamLink = '';
  public byLocParamLink = '';

  public areaCodeInfoAddr: boolean = true;
  public areaCodeInfoLoc: boolean = true;
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'npa nxx lookup by address, Local Exchange Carrier, carrier route, ILEC, ' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else{
      this.setParamLink(docsURL);
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  setParamLink = function (docsURL) {
    this.byAddrParamLink = docsURL + 'TelecommInfo/by_add/query_param.html';
    this.byLocParamLink = docsURL + 'TelecommInfo/by_loc/query_param.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_rcByAddrRes':
        this.get_rcByAddrUri = "";
        this.get_rcByAddrRes = null;
        this.get_rcByAddrResStatus = "";
        this.get_rcByAddrResType = "";
        break;
      case 'get_rcByLocRes':
        this.get_rcByLocUri = "";
        this.get_rcByLocRes = null;
        this.get_rcByLocResStatus = "";
        this.get_rcByLocResType = "";
        break;
    }
  };
  public geoCommTryApiRateCenterByAddraddress = '363 MULIWAI DR WAILUKU, HI  96793';
  public geoCommTryApiRateCenterByAddrlevel = 'detail';
  public geoCommTryApiRateCenterByAddrcountry = 'USA';
  public geoCommTryApiRateCenterByAddrDataType = 'json';
  public get_rcByAddrUri = "";
  public get_rcByAddrRes = null;
  public get_rcByAddrResStatus = "";
  public get_rcByAddrResType = "";
  tryApiGeoCommRateCenterByAddr = function (btnId) {
    var address = this.geoCommTryApiRateCenterByAddraddress;
    var level = this.geoCommTryApiRateCenterByAddrlevel;
    var country = this.geoCommTryApiRateCenterByAddrcountry;
    var dataType = this.geoCommTryApiRateCenterByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    tmpData['level'] = level;
    tmpData['country'] = country;
    tmpData['areaCodeInfo'] = this.areaCodeInfoAddr;
    if (address != '') {
      var methodName = '/tryApiGeoCommRateCenterByAddr';
      let requestUri = this.liapiservice.getCompleteUri('telecomm', 'Rate Center By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_rcByAddrUri = requestUri;
          this.get_rcByAddrResType = dataType;
          if(this.get_rcByAddrResStatus.includes('429')){
            this.get_rcByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_rcByAddrResStatus = '200 success';
            this.get_rcByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_rcByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_rcByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoCommTryApiRateCenterByLoclatitude = 20.8884783;
  public geoCommTryApiRateCenterByLoclongitude = -156.5086309;
  public geoCommTryApiRateCenterByLoclevel = 'detail';
  public geoCommTryApiRateCenterByLocDataType = 'json';
  public get_rcByLocUri = "";
  public get_rcByLocRes = null;
  public get_rcByLocResStatus = "";
  public get_rcByLocResType = "";
  tryApiGeoCommRateCenterByLoc = function (btnId) {
    var latitude: any = this.geoCommTryApiRateCenterByLoclatitude;
    var longitude: any = this.geoCommTryApiRateCenterByLoclongitude;
    var level = this.geoCommTryApiRateCenterByLoclevel;
    var dataType = this.geoCommTryApiRateCenterByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    tmpData['level'] = level;
    tmpData['areaCodeInfo'] = this.areaCodeInfoLoc;
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoCommRateCenterByLoc';
        let requestUri = this.liapiservice.getCompleteUri('telecomm', 'Rate Center By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_rcByLocUri = requestUri;
            this.get_rcByLocResType = dataType;
            if(this.get_rcByLocResStatus.includes('429')){
              this.get_rcByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_rcByLocResStatus = '200 success';
              this.get_rcByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_rcByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_rcByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
