import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";
import { UserService } from '../service/user.service';

@Component({
  selector: 'geocode-demo',
  templateUrl: './geocodedemo.component.html'
})
export class GeocodeDemoComponent implements OnInit {
  public activetab = "forward";
  public forgeocodetype = "premium";
  public revgeocodetype = "premium";
  public forgeocoderes = {
    premium: "",
    advanced: "",
    basic: ""
  };
  public revgeocoderes = {
    premium: "",
    advanced: "",
    basic: ""
  };
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public latitude;
  public longitude;
  public resMarkers = [];
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) { };
  ngOnInit() {
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    var that = this;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      that.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    if (this.activetab == 'reverse') {
      this.sampleDemoGeoCodeReverseGeocode(latlng.lat, latlng.lng);
    }
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        that.sampleDemoGeoCodeReverseGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  setforgeocodetype = function (type) {
    this.forgeocodetype = type;
    this.renderForRes(this.forgeocodetype);
  };
  setrevgeocodetype = function (type) {
    this.revgeocodetype = type;
    this.renderRevRes(this.revgeocodetype);
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removeMarkerLayes();
    if (this.activetab == 'forward') {
      this.renderForRes(this.forgeocodetype);
    }
    else {
      this.renderRevRes(this.revgeocodetype);
    }
  };
  removeMarkerLayes = function () {
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.resMarkers.length != 0) {
      for (let i = 0; i < this.resMarkers.length; i++) {
        this.map.removeLayer(this.resMarkers[i]);
      }
    }
    this.resMarkers = [];
  };
  sampleDemoGeoCodeForwardGeocode = function () {
    $('#geoCodeForwardError').hide();
    $('#geoCodeForwardTab').hide();
    $('#geoCodeForwardResult').hide();
    var tmpData = {
      maxCands: 3
    };
    let mainAddress = $('.pb-geo-input-text').val().toString();
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        tmpData["country"] = this.byAddrCountry.code;
      }
      else {
        tmpData["country"] = this.byAddrCountry;
      }
    }
    if (mainAddress != '') {
      $('.geoApisDemoMapLoader').show();
      tmpData["mainAddress"] = mainAddress;
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoForwardGeocodePremium', tmpData).subscribe(
        success => {
          this.forgeocoderes.premium = success;
          this.setforgeocodetype("premium");
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('.geoApisDemoMapLoader').hide();
          }
        }
      );
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoForwardGeocodeAdvanced', tmpData).subscribe(
        success => {
          this.forgeocoderes.advanced = success;
        },
        error => {
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('.geoApisDemoMapLoader').hide();
          }
        }
      );
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoForwardGeocodeBasic', tmpData).subscribe(
        success => {
          this.forgeocoderes.basic = success;
        },
        error => {
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('.geoApisDemoMapLoader').hide();
          }
        }
      );
    }
    else {
      $('#geoCodeForwardError').html('Address cannot be blank.');
      $('#geoCodeForwardError').show();
    }
  };
  renderForRes = function (type) {
    this.removeMarkerLayes();
    let locationsArr = [];
    if (this.forgeocoderes[type] != "") {
      let tmpHtml = '';
      for (let i = 0; i < this.forgeocoderes[type].candidates.length; i++) {
        tmpHtml += '<h2 class="zeromargintop">';
        if (type == 'premium') {
          tmpHtml += 'P';
        }
        else if (type == 'advanced') {
          tmpHtml += 'A';
        }
        else {
          tmpHtml += 'B';
        }
        tmpHtml += (i + 1) + '</h2>';
        tmpHtml += '<table class="table table-condensed table-unstyled">';
        tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].geometry ? this.forgeocoderes[type].candidates[i].geometry.coordinates[1] : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Longitude</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].geometry ? this.forgeocoderes[type].candidates[i].geometry.coordinates[0] : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">Address Number</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.addressNumber ? this.forgeocoderes[type].candidates[i].address.addressNumber : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Street Name</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.streetName ? this.forgeocoderes[type].candidates[i].address.streetName : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td colspan="2"><div class="resultKey">Place Name</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.placeName ? this.forgeocoderes[type].candidates[i].address.placeName : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">Main address line</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.mainAddressLine ? this.forgeocoderes[type].candidates[i].address.mainAddressLine : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Address last line</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.addressLastLine ? this.forgeocoderes[type].candidates[i].address.addressLastLine : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">Areaname 1</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.areaName1 ? this.forgeocoderes[type].candidates[i].address.areaName1 : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Areaname 2</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.areaName2 ? this.forgeocoderes[type].candidates[i].address.areaName2 : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">Areaname 3</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.areaName3 ? this.forgeocoderes[type].candidates[i].address.areaName3 : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Areaname 4</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.areaName4 ? this.forgeocoderes[type].candidates[i].address.areaName4 : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">Postcode 1</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.postCode1 ? this.forgeocoderes[type].candidates[i].address.postCode1 : '-';
        tmpHtml += '</div></td>';
        tmpHtml += '<td><div class="resultKey">Postcode 2</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.postCode2 ? this.forgeocoderes[type].candidates[i].address.postCode2 : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '<tr><td colspan="2"><div class="resultKey">Country</div><div class="resultVal">';
        tmpHtml += this.forgeocoderes[type].candidates[i].address.country ? this.forgeocoderes[type].candidates[i].address.country : '-';
        tmpHtml += '</div></td></tr>';
        tmpHtml += '</table>';
        var latitude = this.forgeocoderes[type].candidates[i].geometry.coordinates[1];//latitude
        var longitude = this.forgeocoderes[type].candidates[i].geometry.coordinates[0];//longitude
        var markerText = '';
        var iconClass = '';
        if (type == 'premium') {
          markerText = 'P';
          iconClass = 'premiumIcon';
        }
        else if (type == 'advanced') {
          markerText = 'A';
          iconClass = 'premiumIcon';
        }
        else {
          markerText = 'B';
          iconClass = 'basicIcon';
        }
        markerText += (i + 1);
        let resMarkerIcon = {
          icon: L.divIcon({
            iconSize: [56, 56],
            iconAnchor: [28, 56],
            popupAnchor: [0, -44],
            className: iconClass,
            html: markerText
          })
        };
        let resMarker = L.marker([latitude, longitude], resMarkerIcon).addTo(this.map);
        this.resMarkers.push(resMarker);
        locationsArr.push([latitude, longitude]);
      }
      if (tmpHtml == '') {
        tmpHtml = '<div class="geoApisDemoError" style="display:block; margin-top:10px;">No information available for this address.</div>';
      }
      if (locationsArr.length > 1) {
        this.map.fitBounds(locationsArr);
      }
      else {
        if (locationsArr.length != 0) {
          this.map.setView(L.latLng(locationsArr[0][0], locationsArr[0][1]), 14);
        }
      }
     document.getElementById(type+'_fortab').innerHTML =decodeURI(encodeURI(tmpHtml));
      $('#geoCodeForwardTab').show();
      $('#geoCodeForwardResult').show();
    }
  };
  sampleDemoGeoCodeReverseGeocodeInput = function () {
    $('#geoCodeReverseError').hide();
    $('#geoCodeReverseTab').hide();
    $('#geoCodeReverseResult').hide();
    var lat: any = $('#geoCodeSampleDemoLatitude').val();
    var lng: any = $('#geoCodeSampleDemoLongitude').val();
    if (lat != '' && lng != '' && !isNaN(lat) && !isNaN(lng)) {
      this.sampleDemoGeoCodeReverseGeocode(lat, lng);
    }
    else {
      $('#geoCodeReverseError').html('Latitude and Longitude cannot be blank and must be numbers.');
      $('#geoCodeReverseError').show();
      $('#geoCodeReverseTab').hide();
      $('#geoCodeReverseResult').hide();
    }
  };
  sampleDemoGeoCodeReverseGeocode = function (lat, lng) {
    $('#geoCodeReverseError').hide();
    $('#geoCodeReverseTab').hide();
    $('#geoCodeReverseResult').hide();
    this.removeMarkerLayes();
    this.activetab = "reverse";
    $('#geoCodeSampleDemoLatitude').val(lat);
    $('#geoCodeSampleDemoLongitude').val(lng);
    this.latitude = lat;
    this.longitude = lng;
    var tmpData = {
      y: lat,
      x: lng
    };
    $('.geoApisDemoMapLoader').show();
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoReverseGeocodePremium', tmpData).subscribe(
      success => {
        this.revgeocoderes.premium = success;
        this.setrevgeocodetype("premium");
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoReverseGeocodeAdvanced', tmpData).subscribe(
      success => {
        this.revgeocoderes.advanced = success;
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoReverseGeocodeBasic', tmpData).subscribe(
      success => {
        this.revgeocoderes.basic = success;
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  renderRevRes = function (type) {
    this.removeMarkerLayes();
    if (this.revgeocoderes[type] != "") {
      var tmpHtml = '<table class="table table-condensed table-unstyled">';
      tmpHtml += '<tr><td><div class="resultKey">Address number</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.addressNumber ? this.revgeocoderes[type].candidates[0].address.addressNumber : '-';
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Street Name</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.streetName ? this.revgeocoderes[type].candidates[0].address.streetName : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td colspan="2"><div class="resultKey">Place name</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.placeName ? this.revgeocoderes[type].candidates[0].address.placeName : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Main address line</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.mainAddressLine ? this.revgeocoderes[type].candidates[0].address.mainAddressLine : '-';
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Address last line</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.addressLastLine ? this.revgeocoderes[type].candidates[0].address.addressLastLine : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Areaname 1</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.areaName1 ? this.revgeocoderes[type].candidates[0].address.areaName1 : '-';
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Areaname 2</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.areaName2 ? this.revgeocoderes[type].candidates[0].address.areaName2 : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Areaname 3</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.areaName3 ? this.revgeocoderes[type].candidates[0].address.areaName3 : '-';
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Areaname 4</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.areaName4 ? this.revgeocoderes[type].candidates[0].address.areaName4 : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Postcode 1</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.postCode1 ? this.revgeocoderes[type].candidates[0].address.postCode1 : '-';
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Postcode 2</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.postCode2 ? this.revgeocoderes[type].candidates[0].address.postCode2 : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td colspan="2"><div class="resultKey">Country</div><div class="resultVal">';
      tmpHtml += this.revgeocoderes[type].candidates[0].address.country ? this.revgeocoderes[type].candidates[0].address.country : '-';
      tmpHtml += '</div></td></tr>';
      tmpHtml += '</table>';
      document.getElementById(type+'_revtab').innerHTML =decodeURI(encodeURI(tmpHtml));
      this.map.setView(L.latLng(this.latitude, this.longitude), 14);
      this.newMarker = L.marker([this.latitude, this.longitude], this.markerIcon).addTo(this.map);
      $('#geoCodeReverseTab').show();
      $('#geoCodeReverseResult').show();
    }
  };
}
