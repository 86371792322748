import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'timezone-demo',
  templateUrl: './timezonedemo.component.html'
})
export class TimezoneDemoComponent implements OnInit {
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    this.geoApisGetAddressGeocode(latlng.lat, latlng.lng);
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoTimeDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    $('#geoTimeDemoSearchError').hide();
    $('#geoTimeTimezoneError').hide();
    $('#geoTimeTimezoneResult').html('');
    if (address != '') {
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoTimeDemoSearchError').html('Address cannot be blank.');
      $('#geoTimeDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoTimeByLocation(lat, lng);
        }
        else {
          $('#geoTimeTimezoneError').hide();
          $('#geoTimeTimezoneResult').html('');
          $('#geoTimeDemoSearchError').html('No information available for this address.');
          $('#geoTimeDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoTimeByLocation = function (lat, lng) {
    $('#geoTimeTimezoneError').hide();
    $('#geoTimeTimezoneResult').html('');
    var timestamp = new Date().getTime();
    var tmpData = {
      timestamp: timestamp,
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoTimeTimezoneByLoc', tmpData).subscribe(
      success => {
        var currentTimeTS = success.timestamp + success.dstOffset + success.utcOffset;
        var currentTimeFD = new Date(currentTimeTS);
        var currentTimeFinalUTC = currentTimeFD.toUTCString();
        var rePlacedStringDate = currentTimeFinalUTC.replace(",", " ");
        var resStringData = rePlacedStringDate.split(" ");
        var currentTimePopup = resStringData[5];
        var finalStringResult = resStringData[0] + ' ' + resStringData[1] + ' ' + resStringData[2] + ' ' + resStringData[3] + ' ' + resStringData[4] + ' ' + resStringData[5];
        var timeZoneLabel;
        if (success.timezoneName == '') {
          timeZoneLabel = 'Timezone Name Not Available';
        }
        else {
          timeZoneLabel = success.timezoneName;
        }
        var tmpHtml = '<h2 class="zeromargintop">' + timeZoneLabel + '</h2>';
        tmpHtml += '<table class="table table-condensed table-unstyled">';
        tmpHtml += '<tr><td colspan="2"><div class="resultKey">Current Time</div><div class="resultVal">' + finalStringResult + '</div></td></tr>';
        tmpHtml += '<tr><td><div class="resultKey">DST Offset</div><div class="resultVal">' + success.dstOffset + '</div></td>';
        tmpHtml += '<td><div class="resultKey">UTC Offset</div><div class="resultVal">' + success.utcOffset + '</div></td></tr>';
        tmpHtml += '</table>';
        $('#geoTimeTimezoneResult').html(tmpHtml);
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoTimeTimezoneError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoTimeTimezoneError').html(error.statusText);
        }
        $('#geoTimeTimezoneError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoTimeTimezoneError').hide();
        }
      }
    );
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        that.geoApisGetAddressGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  geoApisGetAddressGeocode = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoTimeDemoSearchError').hide();
    $('#geoTimeTimezoneError').hide();
    $('#geoTimeTimezoneResult').html('');
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.sampleDemoGeoTimeByLocation(lat, lng);
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoTimeDemoSearchError').html('No information available for this location.');
          $('#geoTimeDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
}
