import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';
import { UserService } from '../service/user.service';

@Component({
  selector: 'autocomplete-api',
  templateUrl: './autocompleteapi.component.html'
})
export class AutocompleteApiComponent implements OnInit {
  public searchParamLink = '';
  public searchTab = 'location';
  public search_autoDetectLocation: boolean = true;
  public ipAddress = '';
  public search_matchOnAddressNumber: boolean = false;
  public searchconCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchothCountry: any = '';
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getTypeaheadCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely api, typeahead, typeahead documentation, typeahead search, address autocomplete api, search typeahead, address autocomplete, autocomplete api, global, ' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.searchParamLink = success.docsURL + 'AddressAutocompleteEnterprise/query_param.html';
        }
      );
    }
    else {
      this.searchParamLink = docsURL + 'AddressAutocompleteEnterprise/query_param.html';
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_listRes':
        this.get_listUri = "";
        this.get_listRes = null;
        this.get_listResStatus = "";
        this.get_listResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    this.geoSearchTryApiSearchmaxCandidates = '';
    this.geoSearchTryApiSearchsearch_OnAddressNumber = '';
    this.geoSearchTryApiSearchreturnAdminAreasOnly = '';
    this.geoSearchTryApiSearchincludeRangesDetails = '';
    this.geoSearchTryApiSearchsearchOnUnitInfo = '';
  };
  public geoSearchTryApiSearchsearchText = 'times sq';
  public typeaheadtransactionId = 'e048bc9d-353c-4e6d-9cb1-68b44b65cae2';
  public geoSearchTryApiSearchmaxCandidates = 10;
  public geoSearchTryApiSearchsearch_OnAddressNumber = 'N';
  public geoSearchTryApiSearchreturnAdminAreasOnly = 'N';
  public geoSearchTryApiSearchincludeRangesDetails = 'Y';
  public geoSearchTryApiSearchsearchOnUnitInfo = '';
  public geoSearchTryApiSearchDataType = 'json';
  public geoSearchTryApiSearchlatitude = 40.761819;
  public geoSearchTryApiSearchlongitude = -73.997533;
  public geoSearchTryApiSearchsearchRadius = 52800;
  public geoSearchTryApiSearchsearchRadiusUnit = 'feet';
  public geoSearchTryApiSearchLocareaName1 = '';
  public geoSearchTryApiSearchLocareaName3 = '';
  public geoSearchTryApiSearchLocpostCode = '';
  public geoSearchTryApiSearchareaName1 = '';
  public geoSearchTryApiSearchareaName3 = '';
  public geoSearchTryApiSearchpostCode = '';
  public get_listUri = "";
  public get_listRes = null;
  public get_listResStatus = "";
  public get_listResType = "";
  tryApiGeoSearchSearch = function (btnId) {
    var searchText = this.geoSearchTryApiSearchsearchText;
    var transactionId = this.typeaheadtransactionId;
    var maxCandidates = this.geoSearchTryApiSearchmaxCandidates;
    var matchOnAddressNumber = this.search_matchOnAddressNumber;
    var searchOnAddressNumber = this.geoSearchTryApiSearchsearch_OnAddressNumber;
    var returnAdminAreasOnly = this.geoSearchTryApiSearchreturnAdminAreasOnly;
    var includeRangesDetails = this.geoSearchTryApiSearchincludeRangesDetails;
    var searchOnUnitInfo = this.geoSearchTryApiSearchsearchOnUnitInfo;
    var callApiFlag = false;
    var tmpData = {
      searchText: searchText,
      transactionId: transactionId,
      dataType: 'json'
    };
    if (searchText != '') {
      if (this.searchTab == 'location') {
        var latitude: any = this.geoSearchTryApiSearchlatitude;
        var longitude: any = this.geoSearchTryApiSearchlongitude;
        var searchRadius = this.geoSearchTryApiSearchsearchRadius;
        var searchRadiusUnit = this.geoSearchTryApiSearchsearchRadiusUnit;
        var areaName1 = this.geoSearchTryApiSearchLocareaName1;
        var areaName3 = this.geoSearchTryApiSearchLocareaName3;
        var postCode = this.geoSearchTryApiSearchLocpostCode;
        if (latitude != '' && longitude != '') {
          if (!isNaN(latitude) && !isNaN(longitude)) {
            tmpData['latitude'] = latitude;
            tmpData['longitude'] = longitude;
            if (searchRadius != '') {
              tmpData['searchRadius'] = searchRadius;
            }
            if (searchRadiusUnit != '') {
              tmpData['searchRadiusUnit'] = searchRadiusUnit;
            }
            if (this.searchothCountry != '') {
              if (this.searchothCountry.code) {
                tmpData['country'] = this.searchothCountry.code;
              }
              else {
                tmpData['country'] = this.searchothCountry;
              }
              if (areaName1 != '') {
                tmpData['areaName1'] = areaName1;
              }
              if (areaName3 != '') {
                tmpData['areaName3'] = areaName3;
              }
              if (postCode != '') {
                tmpData['postCode'] = postCode;
              }
            }
            callApiFlag = true;
          }
          else {
            this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
          }
        }
        else {
          this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
        }
      }
      else if (this.searchTab == 'ipaddress') {
        var ipAddress = this.ipAddress;
        var searchRadius = this.geoSearchTryApiSearchsearchRadius;
        var searchRadiusUnit = this.geoSearchTryApiSearchsearchRadiusUnit;
        var areaName1 = this.geoSearchTryApiSearchLocareaName1;
        var areaName3 = this.geoSearchTryApiSearchLocareaName3;
        var postCode = this.geoSearchTryApiSearchLocpostCode;
        tmpData['autoDetectLocation'] = this.search_autoDetectLocation;
        if (ipAddress != '') {
          tmpData['ipAddress'] = ipAddress;
        }
        if (searchRadius != '') {
          tmpData['searchRadius'] = searchRadius;
        }
        if (searchRadiusUnit != '') {
          tmpData['searchRadiusUnit'] = searchRadiusUnit;
        }

        if (this.searchothCountry != '') {
          if (this.searchothCountry.code) {
            tmpData['country'] = this.searchothCountry.code;
          }
          else {
            tmpData['country'] = this.searchothCountry;
          }
          if (areaName1 != '') {
            tmpData['areaName1'] = areaName1;
          }
          if (areaName3 != '') {
            tmpData['areaName3'] = areaName3;
          }
          if (postCode != '') {
            tmpData['postCode'] = postCode;
          }
        }
        callApiFlag = true;
      }
      else if (this.searchTab == 'country') {
        var areaName1 = this.geoSearchTryApiSearchareaName1;
        var areaName3 = this.geoSearchTryApiSearchareaName3;
        var postCode = this.geoSearchTryApiSearchpostCode;
        if (this.searchconCountry != '') {
          if (this.searchconCountry.code) {
            tmpData['country'] = this.searchconCountry.code;
          }
          else {
            tmpData['country'] = this.searchconCountry;
          }
          if (areaName1 != '') {
            tmpData['areaName1'] = areaName1;
          }
          if (areaName3 != '') {
            tmpData['areaName3'] = areaName3;
          }
          if (postCode != '') {
            tmpData['postCode'] = postCode;
          }
          callApiFlag = true;
        }
        else {
          this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
        }
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    tmpData['matchOnAddressNumber'] = matchOnAddressNumber;
    if (searchOnAddressNumber != '') {
      tmpData['searchOnAddressNumber'] = searchOnAddressNumber;
    }
    if (returnAdminAreasOnly != '') {
      tmpData['returnAdminAreasOnly'] = returnAdminAreasOnly;
    }
    if (includeRangesDetails != '') {
      tmpData['includeRangesDetails'] = includeRangesDetails;
    }
    if (searchOnUnitInfo !== '') {
      tmpData['searchOnUnitInfo'] = searchOnUnitInfo;
    }
    if (callApiFlag) {
      var methodName = '/tryApiGeoSearchList';
      let requestUri = this.liapiservice.getCompleteUri('autocomplete', 'List', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_listUri = requestUri;
          this.get_listResType = 'json';
          if(this.get_listResStatus.includes('429')){
            this.get_listUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_listResStatus = '200 success';
            this.get_listRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_listResStatus = `${error.status} ${error.statusText}`;
              this.get_listRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
}
