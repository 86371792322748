import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import { UserService } from '../service/user.service';

@Component({
  selector: 'neighborhoods-api',
  templateUrl: './neighborhoodsapi.component.html'
})
export class NeighborhoodsApiComponent implements OnInit {
  public placeByLocParamLink: any = '';
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely neighborhood data, area classification' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.placeByLocParamLink = success.docsURL + 'Neighborhoods/Place/query_param.html';
        }
      );
    }
    else {
      this.placeByLocParamLink = docsURL + 'Neighborhoods/Place/query_param.html';
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_placeByLocRes':
        this.get_placeByLocUri = "";
        this.get_placeByLocRes = null;
        this.get_placeByLocResStatus = "";
        this.get_placeByLocResType = "";
        break;
    }
  };
  public geoEnrichTryApiPlaceByLoclatitude = 35.0118;
  public geoEnrichTryApiPlaceByLoclongitude = -81.9571;
  public geoEnrichTryApiPlaceByLoclevelHint = '';
  public geoEnrichTryApiPlaceByLocDataType = 'json';
  public get_placeByLocUri = "";
  public get_placeByLocRes = null;
  public get_placeByLocResStatus = "";
  public get_placeByLocResType = "";
  tryApiGeoEnrichPlaceByLoc = function (btnId) {
    var latitude: any = this.geoEnrichTryApiPlaceByLoclatitude;
    var longitude: any = this.geoEnrichTryApiPlaceByLoclongitude;
    var levelHint = this.geoEnrichTryApiPlaceByLoclevelHint;
    var dataType = this.geoEnrichTryApiPlaceByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (levelHint != '') {
      tmpData['levelHint'] = levelHint;
    }
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoEnrichPlaceByLoc';
        let requestUri = this.liapiservice.getCompleteUri('neighborhoods', 'Place By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_placeByLocUri = requestUri;
            this.get_placeByLocResType = dataType;
            if(this.get_placeByLocResStatus.includes('429')){
              this.get_placeByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_placeByLocResStatus = '200 success';
              this.get_placeByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_placeByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_placeByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
