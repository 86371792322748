import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.css']
})
export class ApisComponent implements OnInit {

  public searchParamLink = '';
  public contactLink = 'https://www.precisely.com/contact';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private metaTagService: Meta, private resourceService: ResourceService) {
  };
  ngOnInit() {
    window.scroll(0,0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely api, precisely apis' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.searchParamLink = success.docsURL ;
        }
      );
    }
    else{
      this.searchParamLink = docsURL;
    }
  };
}
