import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { UserService } from '../service/user.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
    selector: 'phoneverification-demo',
    templateUrl: './phoneverificationdemo.component.html'
})
export class PhoneverificationDemoComponent implements OnInit {
    public captchaCheck: boolean = false;
    public isResReady: boolean = false;
    public resPhone: string;
    public isVerified: boolean;
    public resResultCode: string;
    public resCodeObj: any = {100: 'Number is live on telecoms provider network', 310: 'Likely to be dead', 400: 'The phone number contains invalid characters (such as alphabetic characters), contains too many digits or too few digits.'};
    public resCarrier: string;
    public carrierLogoDefault: string = "assets/images/logo_64.png";
    public carrierLogo: string = "assets/images/logo_64.png";
    public resType: string;
    public mapOptions;
    public map;
    public mapLayers: L.Layer[];
    public showpage: boolean = false;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private userService: UserService, private resourceService: ResourceService) { };
    ngOnInit() {
        let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
        this.initializeMapOptions(mapFullURL);
        this.userService.getUserDeatails().subscribe(
          _success => {
            this.showpage = true;
          },
          _error => {
            window.location.href = "/pricing";
          }
        );
    };
    private initializeMapOptions(mapFullURL) {
        this.mapOptions = {
            center: L.latLng(39.828127, -98.579404),
            zoom: 4
        };
        this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
    }
    onMapReady(map: L.Map) {
        this.map = map;
    };
    verifyPhone = function () {
        if (!this.captchaCheck) {
            this.isResReady = false;
            $('#phoneError').html('Please select "I am not a robot".');
            $('#phoneError').show();
            return;
        }
        $('#verifyBtn').prop('disabled', true);
        let phoneNumber: any = $('#phoneNumber').val();
        let postData = {"Input": {"Row": [{"PhoneNumber": phoneNumber }]}};
        let tmpData = {
            dataType: 'json',
            data: JSON.stringify(postData)
        };
        if (phoneNumber != '') {
            this.liapiservice.geoApiSampleDemoPostCall('/tryApiPostValidatePhone', tmpData).subscribe(
                success => {
                    $('#verifyBtn').prop('disabled', false);
                    $('#phoneError').hide();
                    this.isResReady = true;
                    if(success.Output){
                        this.resPhone = success.Output[0].PhoneNumber;
                        this.isVerified = true;
                        this.resResultCode = success.Output[0].ResultCode;
                        this.resCarrier = success.Output[0].CarrierName;
                        this.resType = success.Output[0].PhoneType;
                    }
                },
                error => {
                    $('#verifyBtn').prop('disabled', false);
                    this.isResReady = false;
                    if (error.status == 401) {
                        window.location.href = "/signin";
                    }
                    if (error.error) {
                        $('#phoneError').html(error.error.errors.errorDescription);
                    }
                    else {
                        $('#phoneError').html(error.statusText);
                    }
                    $('#phoneError').show();
                    if (error.status == '429') {
                      this.liapiservice.showPrimeInfo();
                      $('#phoneError').hide();
                    }
                }
            );
        }
        else {
            $('#verifyBtn').prop('disabled', false);
            this.isResReady = false;
            $('#phoneError').html('Invalid or missing phone number.');
            $('#phoneError').show();
        }
    };
}
