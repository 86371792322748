import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import { finalize } from 'rxjs';
import { UserService } from '../service/user.service';

@Component({
  selector: 'emailverification-api',
  templateUrl: './emailverificationapi.component.html'
})
export class EmailverificationApiComponent implements OnInit {
  public ValidateEmailAddresslink = '';
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'email verification api, email address verification, mail address verification, validating email addresses, email verification service, email validation, email checker, email deliverability' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.ValidateEmailAddresslink = success.docsURL + 'EmailVerification/validate_emailaddress/query_param.html';
        }
      );
    }
    else{
      this.ValidateEmailAddresslink = docsURL + 'EmailVerification/validate_emailaddress/query_param.html';
    }
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  clearResult = function (result) {
    switch (result) {
      case 'post_emailAddrRes':
        this.post_emailAddrUri = "";
        this.post_emailAddrBody = null;
        this.post_emailAddrRes = null;
        this.post_emailAddrResStatus = "";
        this.post_emailAddrResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  public atc = 'a';
  public bogus = 'true';
  public complains = 'true';
  public disposable = 'true';
  public emailAddress = 'namo@hotmal.com';
  public emps = 'true';
  public fccWireless = 'true';
  public language = 'true';
  public role = 'true';
  public rtc = 'true';
  public rtc_timeout = 1200;
  public dataType = 'json';
  public alias = 'N';
  public post_emailAddrUri = "";
  public post_emailAddrBody = null;
  public post_emailAddrRes = null;
  public post_emailAddrResStatus = "";
  public post_emailAddrResType = "";
  tryApiValidateEmailAddress = function (btnId) {
    var atc = this.atc;
    var bogus = this.bogus;
    var complain = this.complains;
    var disposable = this.disposable;
    var emailaddress = this.emailAddress;
    var emps = this.emps;
    var fccwireless = this.fccWireless;
    var language = this.language;
    var role = this.role;
    var rtc = this.rtc;
    var rtc_timeout = this.rtc_timeout;
    var dataType = this.dataType;
    var alias = this.alias;
    var requestData;
    if (dataType == 'json') {
      requestData = '{"options": { "alias":"' + alias + '"}, "Input": {"Row": [{"atc": "' + atc + '", "bogus": "' + bogus + '", "complain": "' + complain + '", "disposable": "' + disposable + '","emailAddress": "' + emailaddress + '", "emps": "' + emps + '", "fccwireless": "' + fccwireless + '", "language": "' + language + '", "role": "' + role + '", "rtc": "' + rtc + '", "rtc_timeout": "' + rtc_timeout + '"}]}}';
    } else {
      requestData = '<ValidateEmailAddressAPIRequest><options><alias>' + alias + '</alias></options><Input><Row><atc>'  + atc  + '</atc><bogus>' + bogus + '</bogus><complain>' + complain + '</complain><disposable>' + disposable + '</disposable><emailAddress>' + emailaddress + '</emailAddress><emps>' + emps + '</emps><fccwireless>' + fccwireless + '</fccwireless><language>' + language + '</language><role>' + role + '</role><rtc>' + rtc + '</rtc><rtc_timeout>' + rtc_timeout + '</rtc_timeout></Row></Input></ValidateEmailAddressAPIRequest>';
    }
    var tmpData = {
      dataType: dataType,
      data: requestData
    };
    var methodName = '/tryApiValidateEmailAddressRest';
    let requestUri = this.liapiservice.getCompleteUriPostLikeGet('emailverification', 'ValidateEmailAddress', dataType);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoAPIsTryApiBatchLikeGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.post_emailAddrUri = requestUri;
        this.post_emailAddrResType = dataType;
        this.post_emailAddrBody = this.liapiservice.getFormattedResponse(dataType, requestData);
        if(this.post_emailAddrResStatus.includes('429')){
          this.post_emailAddrUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
    )).subscribe(
      success => {
        this.post_emailAddrResStatus = '200 success';
        this.post_emailAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
      },
      error => {
        if (error.status && error.statusText) {
          this.post_emailAddrResStatus = `${error.status} ${error.statusText}`;
          this.post_emailAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
        }
      }
    );
  };
}
