import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import { UserService } from '../service/user.service';

@Component({
  selector: 'timezone-api',
  templateUrl: './timezoneapi.component.html'
})
export class TimezoneApiComponent implements OnInit {
  public byAddrParamLink = '';
  public timezoneByAddrJson = '{"preferences": {"matchMode": "Relaxed"},"addressTime": [{"timestamp": 1691138974831,"address": { "objectId": "1","mainAddressLine": "1805 33rd Street","addressLastLine": "","placeName": "","areaName1": "CO","areaName2": "","areaName3": "Boulder","areaName4": "","postCode": "80301","postCodeExt": "","country": "USA","addressNumber":"","streetName": "","unitType": "","unitValue": "" }},{"timestamp": 1691138974831,"address": { "objectId": "2","mainAddressLine": "1 global view","addressLastLine": "","placeName": "",  "areaName1": "NY","areaName2": "","areaName3": "Troy","areaName4": "","postCode": "12180","postCodeExt": "","country": "USA","addressNumber":"","streetName": "","unitType": "","unitValue": "" } }]}';
  public timezoneByLocJson = '{"locationTime": [{"objectId": "1","timestamp": 1691138974831,"geometry":{ "type":"point","coordinates":[-89.398528,40.633125]}},{"objectId": "2","timestamp": 1691138974831,"geometry":{  "type":"point","coordinates":[-88.398528,40.633125]}}]}';
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  countries: any[];
  filteredCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'utc time api, global time zones, UTC offset, DST offset, ' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.byAddrParamLink = success.docsURL + 'TimeZone/Timezone_address/query_param.html';
        }
      );
    }
    else{
      this.byAddrParamLink = docsURL + 'TimeZone/Timezone_address/query_param.html';
    }
    this.formatJsonInputs();
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_timeByAddrRes':
        this.get_timeByAddrUri = "";
        this.get_timeByAddrRes = null;
        this.get_timeByAddrResStatus = "";
        this.get_timeByAddrResType = "";
        break;
      case 'post_timeByAddrRes':
        this.post_timeByAddrUri = "";
        this.post_timeByAddrRes = null;
        this.post_timeByAddrResStatus = "";
        this.post_timeByAddrResType = "";
        break;
      case 'get_timeByLocRes':
        this.get_timeByLocUri = "";
        this.get_timeByLocRes = null;
        this.get_timeByLocResStatus = "";
        this.get_timeByLocResType = "";
        break;
      case 'post_timeByLocRes':
        this.post_timeByLocUri = "";
        this.post_timeByLocRes = null;
        this.post_timeByLocResStatus = "";
        this.post_timeByLocResType = "";
        break;
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  public geoTimeTryApiTimezoneByAddraddress = '950 Josephine Street Denver CO 80204';
  public geoTimeTryApiTimezoneByAddrtimestamp = 1691138974831;
  public geoTimeTryApiTimezoneByAddrmatchMode = 'Relaxed';
  public geoTimeTryApiTimezoneByAddrDataType = 'json';
  public get_timeByAddrUri = "";
  public get_timeByAddrRes = null;
  public get_timeByAddrResStatus = "";
  public get_timeByAddrResType = "";
  tryApiGeoTimeTimezoneByAddr = function (btnId) {
    var address = this.geoTimeTryApiTimezoneByAddraddress;
    var timestamp: any = this.geoTimeTryApiTimezoneByAddrtimestamp;
    var matchMode = this.geoTimeTryApiTimezoneByAddrmatchMode;
    var dataType = this.geoTimeTryApiTimezoneByAddrDataType;
    var tmpData = {
      address: address,
      timestamp: timestamp,
      dataType: dataType
    };
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        tmpData['country'] = this.byAddrCountry.code;
      }
      else {
        tmpData['country'] = this.byAddrCountry;
      }
    }
    if (matchMode != '') {
      tmpData['matchMode'] = matchMode;
    }
    if (address != '' && timestamp != '' && !isNaN(timestamp)) {
      var methodName = '/tryApiGeoTimeTimezoneByAddr';
      let requestUri = this.liapiservice.getCompleteUri('timezone', 'Get Timezone By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_timeByAddrUri = requestUri;
          this.get_timeByAddrResType = dataType;
          if(this.get_timeByAddrResStatus.includes('429')){
            this.get_timeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_timeByAddrResStatus = '200 success';
            this.get_timeByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_timeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_timeByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_timeByAddrUri = "";
  public post_timeByAddrRes = null;
  public post_timeByAddrResStatus = "";
  public post_timeByAddrResType = "";
  geoTimeTryApiPostTimezoneByAddr = function (btnId) {
    var postData = this.timezoneByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoTimeTryApiPostTimezoneByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('timezone', 'Post Timezone By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_timeByAddrUri = requestUri;
          this.post_timeByAddrResType = 'json';
          if(this.post_timeByAddrResStatus.includes('429')){
            this.post_timeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_timeByAddrResStatus = '200 success';
            this.post_timeByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_timeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_timeByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoTimeTryApiTimezoneByLoclatitude = 42.5309;
  public geoTimeTryApiTimezoneByLoclongitude = -73.6572;
  public geoTimeTryApiTimezoneByLoctimestamp = 1691138974831;
  public geoTimeTryApiTimezoneByLocDataType = 'json';
  public get_timeByLocUri = "";
  public get_timeByLocRes = null;
  public get_timeByLocResStatus = "";
  public get_timeByLocResType = "";
  tryApiGeoTimeTimezoneByLoc = function (btnId) {
    var latitude: any = this.geoTimeTryApiTimezoneByLoclatitude;
    var longitude: any = this.geoTimeTryApiTimezoneByLoclongitude;
    var timestamp: any = this.geoTimeTryApiTimezoneByLoctimestamp;
    var dataType = this.geoTimeTryApiTimezoneByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      timestamp: timestamp,
      dataType: dataType
    };
    if (latitude != '' && longitude != '' && timestamp != '') {
      if (!isNaN(latitude) && !isNaN(longitude) && !isNaN(timestamp)) {
        var methodName = '/tryApiGeoTimeTimezoneByLoc';
        let requestUri = this.liapiservice.getCompleteUri('timezone', 'Get Timezone By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_timeByLocUri = requestUri;
            this.get_timeByLocResType = dataType;
            if(this.get_timeByLocResStatus.includes('429')){
              this.get_timeByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_timeByLocResStatus = '200 success';
              this.get_timeByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_timeByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_timeByLocrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_timeByLocUri = "";
  public post_timeByLocRes = null;
  public post_timeByLocResStatus = "";
  public post_timeByLocResType = "";
  geoTimeTryApiPostTimezoneByLoc = function (btnId) {
    var postData = this.timezoneByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoTimeTryApiPostTimezoneByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('timezone', 'Post Timezone By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_timeByLocUri = requestUri;
          this.post_timeByLocResType = 'json';
          if(this.post_timeByLocResStatus.includes('429')){
            this.post_timeByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_timeByLocResStatus = '200 success';
            this.post_timeByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_timeByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_timeByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  formatJsonInputs = function () {
    this.timezoneByAddrJson = JSON.stringify(JSON.parse(this.timezoneByAddrJson), undefined, 4);
    this.timezoneByLocJson = JSON.stringify(JSON.parse(this.timezoneByLocJson), undefined, 4);
  };
}
