import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { UserService } from '../service/user.service';

@Component({
  selector: 'routing-demo',
  templateUrl: './routingdemo.component.html'
})
export class RoutingDemoComponent implements OnInit {
  public routetravelmode = 'driving';
  public tcmtravelmode = 'driving';
  public activetab = "route";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public routeMarkers = [];
  public tcmMarkers = [];
  public polygon;
  public routeresponse = null;
  public routeCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public routeCountryCode = 'USA';
  public tcmresponse = null;
  public tcmCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public tcmCountryCode = 'USA';
  public startpoint1 = false;
  public startpoint2 = false;
  public endpoint1 = false;
  public endpoint2 = false;
  routeCountries: any[];
  filteredRouteCountries: any[];
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private userService: UserService) { };
  ngOnInit() {
    this.liapiservice.getRoutingCountries().subscribe(
      success => {
        this.routeCountries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  ngDoCheck() {
    if (this.routeCountry.code) {
      this.routeCountryCode = this.routeCountry.code;
    }
    else {
      this.routeCountryCode = this.routeCountry.toString();
    }
    if (this.tcmCountry.code) {
      this.tcmCountryCode = this.tcmCountry.code;
    }
    else {
      this.tcmCountryCode = this.tcmCountry.toString();
    }
  };
  filterRouteCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredRouteCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removeAllLayers();
    if (this.activetab == 'route') {
      if (this.routeresponse != null) {
        this.renderRoute();
      }
    }
    if (this.activetab == 'tcm') {
      if (this.tcmresponse != null) {
        this.renderTCM();
      }
    }
  };
  removeAllLayers = function () {
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
    if (this.routeMarkers.length != 0) {
      for (let i = 0; i < this.routeMarkers.length; i++) {
        this.map.removeLayer(this.routeMarkers[i]);
      }
    }
    this.routeMarkers = [];
    if (this.tcmMarkers.length != 0) {
      for (let i = 0; i < this.tcmMarkers.length; i++) {
        this.map.removeLayer(this.tcmMarkers[i]);
      }
    }
    this.tcmMarkers = [];
  };
  setroutetravelmode = function (travelmode) {
    this.routetravelmode = travelmode;
    let startAddress = $('#geoRouteDemoSource input').val();
    let endAddress = $('#geoRouteDemoDestination input').val();
    let country = '';
    if (this.routeCountry != '') {
      if (this.routeCountry.code) {
        country = this.routeCountry.code;
      }
      else {
        country = this.routeCountry;
      }
    }
    if (startAddress != '' && endAddress != '' && country != '') {
      this.sampleDemoGeoRouteRoute();
    }
  };
  settcmtravelmode = function (travelmode) {
    this.tcmtravelmode = travelmode;
    let country = '';
    if (this.tcmCountry != '') {
      if (this.tcmCountry.code) {
        country = this.tcmCountry.code;
      }
      else {
        country = this.tcmCountry;
      }
    }
    let startAddrArr = [];
    let endAddrArr = [];
    if ($('#geoRouteDemoStart input').val() != '') {
      startAddrArr.push({ addr: $('#geoRouteDemoStart input').val(), label: 'A' });
    }
    if (this.startpoint1) {
      if ($('#geoRouteDemoStart1 input').val() != '') {
        startAddrArr.push({ addr: $('#geoRouteDemoStart1 input').val(), label: 'B' });
      }
    }
    if (this.startpoint2) {
      if ($('#geoRouteDemoStart2 input').val() != '') {
        startAddrArr.push({ addr: $('#geoRouteDemoStart2 input').val(), label: 'C' });
      }
    }
    if ($('#geoRouteDemoEnd input').val() != '') {
      endAddrArr.push({ addr: $('#geoRouteDemoEnd input').val(), label: 'X' });
    }
    if (this.endpoint1) {
      if ($('#geoRouteDemoEnd1 input').val() != '') {
        endAddrArr.push({ addr: $('#geoRouteDemoEnd1 input').val(), label: 'Y' });
      }
    }
    if (this.endpoint2) {
      if ($('#geoRouteDemoEnd2 input').val() != '') {
        endAddrArr.push({ addr: $('#geoRouteDemoEnd2 input').val(), label: 'Z' });
      }
    }
    if (startAddrArr.length != 0 && endAddrArr.length != 0 && country != '') {
      this.sampleDemoGeoRouteTCM();
    }
  };
  sampleDemoGeoRouteRoute = function () {
    this.routeresponse = null;
    this.removeAllLayers();
    $('#geoRouteRouteError').hide();
    $('#geoRouteRouteResult').html('');
    let startAddress = $('#geoRouteDemoSource input').val();
    let endAddress = $('#geoRouteDemoDestination input').val();
    let country = '';
    if (this.routeCountry != '') {
      if (this.routeCountry.code) {
        country = this.routeCountry.code;
      }
      else {
        country = this.routeCountry;
      }
    }
    if (startAddress != '' && endAddress != '' && country != '') {
      $('.geoApisDemoMapLoader').show();
      let tmpData = {
        startAddress: startAddress,
        endAddress: endAddress,
        country: country,
        db: this.routetravelmode,
        segmentGeometryStyle: 'all',
        directionsStyle: 'Normal',
        distanceUnit: 'mi'
      };
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteRoute', tmpData).subscribe(
        success => {
          if (success.geometry) {
            this.routeresponse = success;
            this.renderRoute();
            let tmpHtml = '<div>';
            tmpHtml += '<div><div>Total Time: ' + success.time + ' ' + success.timeUnit + '</div><div>Total Distance: ' + success.distance + ' ' + success.distanceUnit + '</div></div>';
            tmpHtml += '<div><table class="table table-condensed table-open">';
            for (let i = 0; i < success.routeDirections.length; i++) {
              if (success.routeDirections[i].instruction != '') {
                tmpHtml += '<tr><td><h4 class="zeromargintop">' + success.routeDirections[i].instruction + '</h4></td></tr>';
              }
              tmpHtml += '<tr><td>' + success.routeDirections[i].distance + ' ' + success.routeDirections[i].distanceUnit + ' | ' + success.routeDirections[i].time + ' ' + success.routeDirections[i].timeUnit + '</td></tr>';
            }
            tmpHtml += '</table></div>';
            tmpHtml += '</div>';
            $('#geoRouteRouteResult').html(tmpHtml);
          }
          else {
            $('#geoRouteRouteError').html('No information available for this location.');
            $('#geoRouteRouteError').show();
          }
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            $('#geoRouteRouteError').html(error.error.errors[0].errorDescription);
          }
          else {
            $('#geoRouteRouteError').html(error.statusText);
          }
          $('#geoRouteRouteError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#geoRouteRouteError').hide();
          }
        }
      );
    }
    else {
      $('#geoRouteRouteError').html('Parameters are incorrect or missing.');
      $('#geoRouteRouteError').show();
    }
  };
  renderRoute = function () {
    if (this.routeresponse != null) {
      let locationsArr = [
        [this.routeresponse.geometry.coordinates[0][1], this.routeresponse.geometry.coordinates[0][0]],
        [this.routeresponse.geometry.coordinates[this.routeresponse.geometry.coordinates.length - 1][1], this.routeresponse.geometry.coordinates[this.routeresponse.geometry.coordinates.length - 1][0]]
      ];
      let sourceIcon = {
        icon: L.divIcon({
          iconSize: [30, 40],
          iconAnchor: [15, 40],
          popupAnchor: [0, -44],
          className: 'sourceIcon',
          html: 'A'
        })
      };
      let sourceMarker = L.marker([locationsArr[0][0], locationsArr[0][1]], sourceIcon).addTo(this.map);
      this.routeMarkers.push(sourceMarker);
      let destIcon = {
        icon: L.divIcon({
          iconSize: [30, 40],
          iconAnchor: [15, 40],
          popupAnchor: [0, -44],
          className: 'destIcon',
          html: 'B'
        })
      };
      let destMarker = L.marker([locationsArr[1][0], locationsArr[1][1]], destIcon).addTo(this.map);
      this.routeMarkers.push(destMarker);
      this.polygon = L.geoJSON(this.routeresponse.geometry as any).addTo(this.map);
      this.map.fitBounds(locationsArr);
    }
  };
  sampleDemoGeoRouteTCM = function () {
    this.tcmresponse = null;
    this.removeAllLayers();
    $('#geoRouteTcmError').hide();
    $('#geoRouteTcmResult').html('');
    let country = '';
    if (this.tcmCountry != '') {
      if (this.tcmCountry.code) {
        country = this.tcmCountry.code;
      }
      else {
        country = this.tcmCountry;
      }
    }
    let startAddrArr = [];
    let endAddrArr = [];
    if ($('#geoRouteDemoStart input').val() != '') {
      startAddrArr.push({ addr: $('#geoRouteDemoStart input').val(), label: 'A' });
    }
    if (this.startpoint1) {
      if ($('#geoRouteDemoStart1 input').val() != '') {
        startAddrArr.push({ addr: $('#geoRouteDemoStart1 input').val(), label: 'B' });
      }
    }
    if (this.startpoint2) {
      if ($('#geoRouteDemoStart2 input').val() != '') {
        startAddrArr.push({ addr: $('#geoRouteDemoStart2 input').val(), label: 'C' });
      }
    }
    if ($('#geoRouteDemoEnd input').val() != '') {
      endAddrArr.push({ addr: $('#geoRouteDemoEnd input').val(), label: 'X' });
    }
    if (this.endpoint1) {
      if ($('#geoRouteDemoEnd1 input').val() != '') {
        endAddrArr.push({ addr: $('#geoRouteDemoEnd1 input').val(), label: 'Y' });
      }
    }
    if (this.endpoint2) {
      if ($('#geoRouteDemoEnd2 input').val() != '') {
        endAddrArr.push({ addr: $('#geoRouteDemoEnd2 input').val(), label: 'Z' });
      }
    }
    let startAddresses = '';
    let endAddresses = '';
    if (startAddrArr.length != 0 && endAddrArr.length != 0 && country != '') {
      $('.geoApisDemoMapLoader').show();
      for (let i = 0; i < startAddrArr.length; i++) {
        startAddresses += startAddrArr[i].addr + ' | ';
      }
      for (let i = 0; i < endAddrArr.length; i++) {
        endAddresses += endAddrArr[i].addr + ' | ';
      }
      startAddresses = startAddresses.substring(0, startAddresses.length - 3);
      endAddresses = endAddresses.substring(0, endAddresses.length - 3);
      let tmpData = {
        startAddresses: startAddresses,
        endAddresses: endAddresses,
        country: country,
        db: this.tcmtravelmode,
        returnOptimalRoutesOnly: false,
        distanceUnit: 'mi'
      };
      this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteTCM', tmpData).subscribe(
        success => {
          if (success.matrix) {
            this.tcmresponse = success;
            let tmpHtml = '<div>';
            for (let i = 0; i < this.tcmresponse.matrix.length; i++) {
              let distance = this.tcmresponse.matrix[i].distance + ' ' + this.tcmresponse.matrix[i].distanceUnit;
              let time = this.tcmresponse.matrix[i].time + ' ' + this.tcmresponse.matrix[i].timeUnit;
              let endlabelIndex = i % endAddrArr.length;
              let startlabelIndex = Math.floor(i / endAddrArr.length);
              this.tcmresponse.matrix[i].startPoint.label = startAddrArr[startlabelIndex].label;
              this.tcmresponse.matrix[i].endPoint.label = endAddrArr[endlabelIndex].label;
              let label = startAddrArr[startlabelIndex].label + ' - ' + endAddrArr[endlabelIndex].label;
              tmpHtml += '<ul>';
              tmpHtml += '<li><div><h3>' + label + '</h3></div><div>&nbsp;</div></li>';
              tmpHtml += '<li><div>Distance</div><div>' + distance + '</div></li>';
              tmpHtml += '<li><div>Time</div><div>' + time + '</div></li>';
              tmpHtml += '</ul>';
            }
            tmpHtml += '</div>';
            $('#geoRouteTcmResult').html(tmpHtml);
            this.renderTCM();
          }
          else {
            $('#geoRouteTcmError').html('No information available for this location.');
            $('#geoRouteTcmError').show();
          }
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            $('#geoRouteTcmError').html(error.error.errors[0].errorDescription);
          }
          else {
            $('#geoRouteTcmError').html(error.statusText);
          }
          $('#geoRouteTcmError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#geoRouteTcmError').hide();
          }
        }
      );
    }
    else {
      $('#geoRouteTcmError').html('Parameters are incorrect or missing.');
      $('#geoRouteTcmError').show();
    }
  };
  renderTCM = function () {
    if (this.tcmresponse != null) {
      let locationsArr = [];
      for (let i = 0; i < this.tcmresponse.matrix.length; i++) {
        let sourceIcon = {
          icon: L.divIcon({
            iconSize: [30, 40],
            iconAnchor: [15, 40],
            popupAnchor: [0, -44],
            className: 'sourceIcon',
            html: this.tcmresponse.matrix[i].startPoint.label
          })
        };
        let startmarker = L.marker([this.tcmresponse.matrix[i].startPoint.coordinates[1], this.tcmresponse.matrix[i].startPoint.coordinates[0]], sourceIcon).addTo(this.map);
        this.tcmMarkers.push(startmarker);
        locationsArr.push([this.tcmresponse.matrix[i].startPoint.coordinates[1], this.tcmresponse.matrix[i].startPoint.coordinates[0]]);
        let destIcon = {
          icon: L.divIcon({
            iconSize: [30, 40],
            iconAnchor: [15, 40],
            popupAnchor: [0, -44],
            className: 'destIcon',
            html: this.tcmresponse.matrix[i].endPoint.label
          })
        };
        let endmarker = L.marker([this.tcmresponse.matrix[i].endPoint.coordinates[1], this.tcmresponse.matrix[i].endPoint.coordinates[0]], destIcon).addTo(this.map);
        this.tcmMarkers.push(endmarker);
        locationsArr.push([this.tcmresponse.matrix[i].endPoint.coordinates[1], this.tcmresponse.matrix[i].endPoint.coordinates[0]]);
      }
      this.map.fitBounds(locationsArr);
    }
  };
  addWaypointTcm = function (type) {
    if (type == 'start') {
      if (this.startpoint1 == false) {
        this.startpoint1 = true;
      }
      else if (this.startpoint2 == false) {
        this.startpoint2 = true;
      }
      if (this.startpoint1 == true && this.startpoint2 == true) {
        $('#addwaypointtcmstartbtn').addClass('disabled');
      }
    }
    if (type == 'end') {
      if (this.endpoint1 == false) {
        this.endpoint1 = true;
      }
      else if (this.endpoint2 == false) {
        this.endpoint2 = true;
      }
      if (this.endpoint1 == true && this.endpoint2 == true) {
        $('#addwaypointtcmendbtn').addClass('disabled');
      }
    }
  };
  removeWaypointTcm = function (type, count) {
    if (type == 'start') {
      $('#addwaypointtcmstartbtn').removeClass('disabled');
      switch (count) {
        case 1:
          this.startpoint1 = false;
          break;
        case 2:
          this.startpoint2 = false;
          break;
      }
      return true;
    }
    if (type == 'end') {
      $('#addwaypointtcmendbtn').removeClass('disabled');
      switch (count) {
        case 1:
          this.endpoint1 = false;
          break;
        case 2:
          this.endpoint2 = false;
          break;
      }
      return true;
    }
  };
}
