import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $, { error } from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'streets-demo',
  templateUrl: './streetsdemo.component.html'
})
export class StreetsDemoComponent implements OnInit {
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public resMarkerIcon = {
    icon: L.icon({
      iconSize: [56, 56],
      iconAnchor: [28, 56],
      popupAnchor: [0, -44],
      iconUrl: '/assets/images/single_poi.png'
    })
  };
  public locationsArr = [];
  public newMarker;
  public resMarker;
  public showpage: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private userService: UserService) {};
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    this.userService.getUserDeatails().subscribe(
      _success => {
        this.showpage = true;
      },
      _error => {
        window.location.href = "/pricing";
      }
    );
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    this.geoApisGetAddressGeocode(latlng.lat, latlng.lng);
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoStreetDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = 'USA';
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.resMarker != undefined) {
      this.map.removeLayer(this.resMarker);
    }
    this.locationsArr = [];
    $('#geoStreetDemoSearchError').hide();
    $('#geoStreetmajorinError').hide();
    $('#geoStreetmajorinResult').html('');
    if (address != '') {
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoStreetDemoSearchError').html('Address cannot be blank.');
      $('#geoStreetDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.locationsArr.push([lat, lng]);
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoStreetByLocation(lat, lng);
        }
        else {
          $('#geoStreetmajorinError').hide();
          $('#geoStreetmajorinResult').html('');
          $('#geoStreetDemoSearchError').html('No information available for this address.');
          $('#geoStreetDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoStreetByLocation = function (lat, lng) {
    $('#geoStreetmajorinError').hide();
    $('#geoStreetmajorinResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      areaCodeInfo: true,
      level: 'detail'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoStreetMajInByLoc', tmpData).subscribe(
      success => {
        if (success.intersection) {
          var lat = success.intersection[0].geometry.coordinates[1];
          var lng = success.intersection[0].geometry.coordinates[0];
          this.locationsArr.push([lat, lng]);
          this.resMarker = L.marker([lat, lng], this.resMarkerIcon).addTo(this.map);
          this.map.fitBounds(this.locationsArr);
          var tmpHtml = '<h2 class="zeromargintop">Nearest Intersection</h2>';
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td colspan="2"><div class="resultKey">Road Class</div><div class="resultVal">';
          if (success.intersection[0].roads[0].roadClass) {
            tmpHtml += success.intersection[0].roads[0].roadClass;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Road Name</div><div class="resultVal">';
          if (success.intersection[0].roads[0].name) {
            tmpHtml += success.intersection[0].roads[0].name;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Road Type</div><div class="resultVal">';
          if (success.intersection[0].roads[0].type) {
            tmpHtml += success.intersection[0].roads[0].type;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          if (success.intersection[0].driveTime && success.intersection[0].driveDistance) {
            tmpHtml += '<tr><td><div class="resultKey">Drive Time</div><div class="resultVal">' + parseFloat(success.intersection[0].driveTime.value).toFixed(2) + ' ' + success.intersection[0].driveTime.unit + '</div></td>';
            tmpHtml += '<td><div class="resultKey">Drive Distance</div><div class="resultVal">' + parseFloat(success.intersection[0].driveDistance.value).toFixed(2) + ' ' + success.intersection[0].driveDistance.unit + '</div></td></tr>';
          }
          tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultVal">' + success.intersection[0].geometry.coordinates[1] + '</div></td>';
          tmpHtml += '<td><div class="resultKey">Longitude</div><div class="resultVal">' + success.intersection[0].geometry.coordinates[0] + '</div></td></tr>';
          tmpHtml += '</table>';
          $('#geoStreetmajorinResult').html(tmpHtml);
        }
        else {
          $('#geoStreetmajorinError').html('No information available for this address/location.');
          $('#geoStreetmajorinError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoStreetmajorinError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoStreetmajorinError').html(error.statusText);
        }
        $('#geoStreetmajorinError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoStreetmajorinError').hide();
        }
      }
    );
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        that.geoApisGetAddressGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  geoApisGetAddressGeocode = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoStreetDemoSearchError').hide();
    $('#geoStreetmajorinError').hide();
    $('#geoStreetmajorinResult').html('');
    this.locationsArr = [];
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.resMarker != undefined) {
      this.map.removeLayer(this.resMarker);
    }
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.locationsArr.push([lat, lng]);
          this.sampleDemoGeoStreetByLocation(lat, lng);
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoStreetDemoSearchError').html('No information available for this location.');
          $('#geoStreetDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
}
